.productsAndServices {
  // height: 100%;
  padding: 20px;
  height: calc(100vh - 64px);
  overflow: scroll;
  font-size: 1.3rem;
  background: #fafafa;

  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1.2rem;

    & > .allTabs {
      display: flex;
      align-items: center;
      gap: 15px;
      width: calc(100% - 100px);

      & > div {
        padding: 0.9rem 1.5rem;
        border-radius: 6px;
        border: 1px solid #d9d9d9;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          border: 1px solid #000000;
          font-weight: 510;
        }
      }

      & > .selectedTab {
        background: #58b135;
        color: white;
      }
    }

    & > .submitBtn {
      display: flex;
      gap: 10px;
      align-items: center;
      cursor: pointer;
      font-weight: 510;
      cursor: pointer;
      padding: 0.7rem 1.5rem;
      white-space: nowrap;
      font-size: 1.5rem;

      & > svg {
        color: #58b135;
        font-size: 2rem;
      }
    }
  }

  & > .contentContainer {
    // height: calc(100% - 3.2rem);
    // border: 1px solid red;
    // overflow-y: scroll;

    .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 2rem 0;
    }

    .allSelects {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & > div {
        padding: 1.5rem 1rem;
        background: white;
        border-radius: 7px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 1px solid #d9d9d9;
        align-items: center;

        & > .profile {
          width: 200px;
          display: flex;
          align-items: center;
          gap: 1.2rem;

          & > img {
            height: 7rem;
            width: 7rem;
            border-radius: 50%;
          }
          & > .title {
            color: #000000;
            font-weight: 500;
          }
        }

        & > .desc {
          width: calc(100% - 230px);
          line-height: 1.5;
        }
      }

      & > .selectedServices {
        border-color: #58b135;
      }
    }
  }
}

.servicesTab {
  & > .createSelectBtn {
    background: white;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0.5px solid #e5e5e5;
    border-radius: 6px;
    color: #47474680;
    margin-top: 1rem;
    transition: all linear 0.3s;

    &:hover {
      border-color: #58b135;
      color: #58b135;
    }

    & > svg {
      font-size: 5rem;
    }
  }

  & > .createService {
    background: white;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0.5px solid #e5e5e5;
    border-radius: 6px;
    color: #47474680;
    margin-top: 1rem;

    & > .flexDiv {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
      align-items: center;
      width: 100%;
      margin-bottom: 1.5rem;

      & > div {
        width: 33%;
      }
    }

    & > :nth-child(2) {
      width: 100%;
    }

    & > .btnsContainer {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      width: 100%;

      & > div {
        padding: 1rem 2rem;
        border-radius: 6px;
        cursor: pointer;
        font-weight: 510;
        border: 1px solid #d9d9d9;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }

      & > .coloredBtn {
        background: #58b135;
        border-color: #58b135;
        color: white;
      }
    }
  }
}
