.advisor-profile-edit {
    padding: 20px;
  
    .form-container {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      background: #fff;
      gap: 30px;
  
      .left-section, .right-section {
        flex: 1;
        // margin-right: 20px;
      }
  
      .left-section {
        display: flex;
        flex-direction: column;
  
        .form-group {
          margin-bottom: 15px;
  
          label {
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 23.45px;
            color: #525252;
            display: block;
            margin-bottom: 5px;
          }
  
          input, textarea {
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 23.45px;
            color: #525252;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 10px;
            width: 100%;
            box-shadow: 1px 1px 4px 0px #00000040;

          }
        }
      }
  
      .right-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
  
        .form-group {
          margin-bottom: 15px;
  
          label {
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 23.45px;
            color: #525252;
            display: block;
            margin-bottom: 3px;
          }
  
          textarea {
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 23.45px;
            color: #525252;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 10px;
            width: 100%;
            height: 225px;
            resize: none;
            box-shadow: 1px 1px 4px 0px #00000040;

          }
        }
  
        .profile-pictures {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 40px;
  
          .profile-picture-item {
            display: flex;
            flex-direction: column;
            align-items: center;
  
            label {
              margin-bottom: 5px;
              font-family: 'Hanken Grotesk', sans-serif;
              font-size: 16px;
              font-weight: 400;
              color: #525252;
              line-height: 23.45px;
            }
  
            img {
              width: 100px;
              height: 100px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
  
      }

    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      padding: 20px;

      .cancel-button, .save-button {
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 26.06px;
        text-align: center;
        padding: 10px 24px;
        border-radius: 16px;
        cursor: pointer;
      }

      .cancel-button {
        color: #1d5b05;
        background: none;
        border: 1px solid #1d5b05;
      }

      .save-button {
        color: #fff;
        background: #58B135;
        border: none;
      }
    }
  }
  