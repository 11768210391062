.headerNav {
  height: 5.5rem;
  // background-color: #58B135;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 14rem;
  position: sticky;
  top: 0;
}

.leftLogo {
  width: 10rem;
  height: 5rem;
  // background-color: white;
  // border-radius: 50%;
  object-fit: contain;
  // border: 1px solid #fff;
  padding: 1px;
}

.rightMenus {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: white;

  a {
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    text-decoration: none;
  }

  div {
    &:hover {
      transform: scale(1.2);
      transition: all ease-in-out 0.3s;
    }
  }
}

.section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 14rem;
  padding-top: 2rem;
  padding-bottom: 2.3rem;
  border-bottom: 1px solid #e7e7e7;
}

.section2Left {
  display: flex;
  align-items: center;
}

.userAvatar {
  // background-color: lightgray;
  border-radius: 50%;

  img {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
  }
}

.userDetails {
  padding-left: 1.5rem;

  :nth-child(1) {
    font-size: 0.75rem;
    font-weight: 400;
    color: #7a7a7a;
    line-height: 0.914rem;
  }

  :nth-child(2) {
    font-size: 1.8rem;
    font-weight: 500;
    color: #000;
    line-height: 2.286rem;
  }

  :nth-child(3) {
    font-size: 0.875rem;
    font-weight: 600;
    color: #666666;
    line-height: 1.067rem;
  }

  :nth-child(4) {
    font-size: 0.875rem;
    font-weight: 400;
    color: #7a7a7a;
    line-height: 1.067rem;
    padding-top: 2px;
  }
}

.section2Right {
  .btnSecondary {
    width: 11.875rem;
    height: 2.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #30a4d2;
    color: #3d3d3d;
    font-size: 1.125rem;
    font-weight: 600;
    border-radius: 0.5rem;

    &:hover {
      transform: scale(1.1);
      transition: all ease-in-out 0.3s;
      cursor: pointer;
    }
  }

  .btnPrimary {
    width: 11.875rem;
    height: 2.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #30a4d2;
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 0.5rem;
    border-radius: 0.5rem;

    &:hover {
      transform: scale(1.1);
      transition: all ease-in-out 0.3s;
      cursor: pointer;
    }
  }
}

.bannerContainer {
  margin: 1.5rem 14rem;
  padding: 4rem;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;

  & > .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    width: calc(100% - 35rem);

    & > .title {
      font-size: 3rem;
      font-weight: 510;
    }

    & > .subTitle {
      font-size: 1.7rem;
      font-weight: 450;
    }

    & > .desc {
      font-size: 1.2rem;
      line-height: 1.6;
      color: #000 !important;
      font-weight: 400;
      width: 70%;
    }
  }

  & > .imageContainer {
    height: 25rem;
    width: 25rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background-size: cover; /* Optional: Ensure the background image covers the container */
    background-position: center; /* Optional: Center the background image */

    & > img {
      height: 7.5rem;
      width: 7.5rem;
      object-fit: contain;
      border-radius: 50%;
      opacity: 0.6;
    }
  }
}

.section3 {
  padding: 1.5rem 14rem;

  .section3Title {
    font-size: 1.25rem;
    color: #000;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .section3Body {
    font-size: 1.125rem;
    color: #3d3d3d;
    font-weight: 400;
    line-height: 2rem;
    padding-top: 0.625rem;
  }
}

.section4Title {
  font-size: 1.25rem;
  font-weight: 700;
  color: white;
  line-height: 1.524rem;
}

.section4Body {
  font-size: 1rem;
  font-weight: 400;
  color: white;
  line-height: 1.219rem;
  padding-top: 1rem;
}

.readBtn {
  padding: 0.75rem 1rem;
  border: 1px solid white;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  float: right;
  border-radius: 0.625rem;
}

.section5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 5rem;
  margin: 3rem 14rem;
}

.listWrapper {
  display: flex;
  gap: 3rem;
  margin-left: 2rem;

  li {
    color: #3d3d3d;
    font-weight: 400;
    font-size: 1.125rem;
  }
}

.verticalDivider {
  height: 10rem;
  border: 0.5px solid #e7e7e7;
}

.listTitle {
  font-size: 1.25rem;
  font-weight: 700;
  padding-bottom: 0.625rem;
}

.section6 {
  background-color: #136b8e;
  margin: 2.939rem 1.875rem;
  border-radius: 1.25rem;
  padding: 0px 1.438rem;
  padding-top: 2.25rem;
  padding-bottom: 3.813rem;
}

.listTitle1 {
  font-size: 1.25rem;
  font-weight: 700;
  padding-bottom: 0.625rem;
  color: white;
}

.listWrapper1 {
  margin-left: 2rem;

  li {
    color: #fff;
    font-weight: 400;
    font-size: 1.125rem;
  }
}

.listWrapper2 {
  margin-left: 2rem;
  display: flex;
  gap: 3rem;

  li {
    color: #fff;
    font-weight: 400;
    font-size: 1.125rem;
  }
}

.section8 {
  margin: 3.5rem 14rem;
}

.section8Title {
  font-size: 1.25rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.blogWrapper {
  width: 17.25rem;

  // background-color: red;
  &:hover {
    transform: scale(1.05);
    transition: all ease-in-out 0.3s;
  }
}

.blogImg {
  width: 100%;
  height: 10.188rem;
  border-radius: 0.75rem;
}

.blogTitle {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 0.5rem;
  width: 90%;
}

.blogSubTitle {
  font-size: 0.75rem;
  font-weight: 400;
  width: 90%;
}

.footerNav {
  height: 5.5rem;
  background-color: #136b8e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14rem;
  padding-right: 14rem;
}

.footerLeft {
  display: flex;
  align-items: center;
}

.phoneIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.phoneNo {
  font-size: 1.25rem;
  font-weight: 700;
  padding-left: 1.063rem;
  color: white;

  &:hover {
    transform: scale(1.2);
    transition: all ease-in-out 0.3s;
  }
}

.socialLinks {
  width: 1.875rem;
  height: 1.875rem;
  color: white;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
    transition: all ease-in-out 0.3s;
  }
}

.productsTitle {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  padding: 2.313rem 0px;
}

.productsWrapper {
  padding: 0px 14rem;
  margin-bottom: 6.25rem;
}

.productStyle {
  padding-bottom: 3rem;

  &:hover {
    transform: scale(1.03);
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
}

.productName {
  font-size: 1.563rem;
  font-weight: 400;
}

.productDesc {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
  padding-top: 1.563rem;
}

.section4 {
  background-color: #136b8e;
  margin: 2.939rem 14rem;

  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  // height: 35.125rem;
}

.section41 {
  background-color: #136b8e;
  margin: 2.939rem 14rem;

  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  height: 35.125rem;
}

.sec4img {
  width: 40%;
  height: 29.313rem;
  border-radius: 1.25rem 0px 0px 1.25rem;
  // border-radius: 20px;
}

.sec4Text {
  width: 60%;
  height: 29.313rem;
  // background-color: blue;
  border-radius: 0px 1.25rem 1.25rem 0px;
  padding-left: 6.375rem;
  padding-right: 3.438rem;
  padding-top: 4.875rem;
  padding-bottom: 4.875rem;
}

.sec4img1 {
  width: 40%;
  height: 29.313rem;
  // border-radius: 1.25rem 0px 0px 1.25rem;
  border-radius: 20px;
}

.sec4Text1 {
  width: 60%;
  height: 29.313rem;
  // background-color: blue;
  border-radius: 0px 1.25rem 1.25rem 0px;
  // padding-left: 6.375rem;
  padding-right: 3.438rem;
  padding-top: 4.875rem;
  padding-bottom: 4.875rem;
}

.sec4Title {
  font-size: 2.813rem;
  color: white;
  font-weight: 800;
}

.sec4SubTitle {
  font-size: 1.563rem;
  font-weight: 700;
  color: white;
}

.sec4body {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
  color: white;
  padding-top: 2.313rem;
}

.carouselWrapper {
  position: relative;
  padding-bottom: 40px;
  /* Adjust as needed */
}

.carouselContainer {
  position: relative;
}

.productSection {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin: 2.939rem 14rem;
  gap: 1.25rem;
}

.leftSection {
  width: 50%;
  // background-color: red
}

.rightSection {
  width: 50%;
  // background-color: blue;
}

.productCard {
  // background-color: rgba(19, 107, 142, 0.05);
  padding: 2.75rem 2.313rem;
  // border: 1px solid ;
  border-radius: 0.938rem;
  margin-bottom: 1.375rem;

  &:hover {
    transform: scale(1.03);
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
}

.serviceCard {
  // background-color: rgba(19, 107, 142, 0.05);
  padding: 2.75rem 2.313rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.938rem;
  margin-bottom: 1.375rem;

  &:hover {
    transform: scale(1.03);
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
}

.productCardTitle {
  font-size: 1.25rem;
  font-weight: 700;
  padding-bottom: 2.125rem;
}

.productCardSubTitle {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.serviceCardBtn {
  height: 3.688rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 1.9rem;

  &:hover {
    transform: scale(1.02);
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
}
