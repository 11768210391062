.second-section {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    max-height: 1000px;
}

.second-section-content {
    width: 100%;
    /* padding: 30vh 0; */
    height: 60%;
    max-height: 600px;
    display: flex;
    flex-direction: row-reverse;
    min-height: 20vw;
}

.second-section-content>:first-child {
    width: 70%;
    font-size: 4rem;
    font-weight: 700;
    background: #58B135;
    color: white;
    padding: 7rem;
    display: flex;
    align-items: center;
    line-height: 1.5;
    opacity: 0.75;
}

.second-section-content>:last-child {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.second-section-content>:last-child img {
    object-fit: contain;
    width: 70%;
    /* height: 100%; */
}

@media screen and (max-width:750px) {
    .second-section-content>:last-child {
        display: none;
    }

    .second-section-content>:first-child {
        width: 100%;
        padding: 30px;
    }
}

@media screen and (max-width:600px) {
    .second-section {
        min-height: 40vh;
        display: none;
    }
}