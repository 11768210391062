.websitepage {
    display: flex;
    align-items: center;
    justify-content: space-between;



    .first-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5rem;
    }

    .first-content-title {
        font-size: 30px;
        font-weight: 700;
        color: #58B135;
        z-index: 1;
    }

    .first-content-first-title {
        font-size: 48px;
        font-weight: 700;
        color: #110F0D;
        white-space: nowrap;
        z-index: 1;
        padding-bottom: 5px;
        position: relative;
    }

    .first-content-para {
        font-size: 17px;
        color: #110F0D;
        font-weight: 400;
        line-height: 2;
        z-index: 1;
        margin: 2.5rem 0;
    }

    .first-section-btn-parent {
        display: flex;
        gap: 20px;
        margin-top: 1rem;
        z-index: 1;
    }

    .first-section-btn-parent>a {
        background: #58B135;
        font-size: 20px;
        font-weight: 700;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.3rem 2.5rem;
        border-radius: 27rem;
        text-decoration: none;
        gap: 2rem;
        transition: 0.5s;
    }

    .first-section-btn-parent>a>:last-child {
        /* margin-left: 1.5rem; */
        height: 80%;
    }

    .first-section-btn-parent>a>:last-child img {
        height: 100%;
    }

    .first-content-btn {
        font-size: 1.5rem;
        color: #FFFFFF;
        font-weight: 600;
        padding: 1.5rem 5rem;
        background: #58B135;
        width: max-content;
        border-radius: 2rem;
        cursor: pointer;
        background: white;
        border: 1px solid #e5e5e5;
        color: #110F0D;
        display: flex;
        gap: 15px;
    }

    .first-section-btn-mob {
        display: none;
        width: 100%;
        font-size: 2.5rem;
        font-weight: 600;
        gap: 15px;
    }

    .first-section-btn-mob>div {
        border-radius: 20px;
        padding: 10px 25px;
        width: 48%;
        text-align: center;
        max-width: 150px;
    }
}

.webfullpage {
    max-width: 1250px;
    margin: auto;
    height: 100vh;
    overflow: scroll;
    padding-top: 12rem;

    &::-webkit-scrollbar {
        display: none;
    }

    .websitecard {
        border-radius: 0px 0px 35px 35px;
        border: 0.5px solid #E5E5E5;
        height: 260px;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 232px;
            height: 174px;
            border-radius: 25px;
            border: 0.5px solid #E5E5E5;
            background: #FFF;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .tabwebiste {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6rem;
        height: 64px;
        border-radius: 35px 35px 0px 0px;
        border: 0.5px solid #E5E5E5;
        background: #FFF;



        .title {
            color: #110F0D;
            text-align: center;
            font-family: Montserrat;
            position: relative;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            display: flex;
            align-items: center;
            height: 64px;

            .borderlisss {
                position: absolute;
                bottom: 0;
                height: 2px;
                width: 70%;
                background: #110F0D;
                left: 0;
                right: 0%;
                margin: auto;
            }

            &:hover {
                transform: scale(1.03);
            }

            /* 240% */

        }
    }
}