.locationPage {
    border-bottom: 1px solid #E5E5E5;
    background: #FFF;
    // padding-left: 30px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    height: 76px;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;


    .backbutton {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #E5E5E5;
        width: 76px;
        height: 76px;
        position: absolute;
        left: 2px;
        cursor: pointer;

        &:hover {
            svg {
                transform: scale(1.04);
            }

        }



    }

    img {
        width: 22px;
        height: 14px;

    }

    .droplocation {
        display: flex;
        align-items: center;
        color: #110F0D;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }

    }

    .dropdownlist {
        width: 227px;
        height: 324px;
        border-radius: 10px;
        border: 1px solid #E5E5E5;
        background: #FFF;
        position: absolute;
        top: 80px;
        right: 40px;
        z-index: 1;
        padding: 2rem;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}