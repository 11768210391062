.reportPage {
    padding: 20px;
  }

.adReport{
    padding: 0 6vw;
}
  .rightsidedatashow{
    overflow-y: auto;
        padding-bottom: 100px;
        height: 100%;
        
  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 20px;
    padding: 20px 35px;

  
    .backButton {
      background: none;
      border: none;
      cursor: pointer;

      img{
        width: 25px;
        height: auto;
        object-fit: contain;
      }
    }
  
    .actionButtons {
      display: flex;
      gap: 10px;
      
  
      .approveButton, .rejectButton {
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.24px;
        color: #000000;
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;
        background-color: #ffffff;
      }
  
      .approveButton {
        border: 2px solid #1A8825;
      }
  
      .rejectButton {
        border: 2px solid #881A1A;
      }
  
      .downloadButton {

        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: none;
        box-shadow: 1px 1px 4px 1px #00000040;
        cursor: pointer;
        color: white;
        font-size: 18px;
        background-color: white;
      }
    }
  }
}
  
  .reportContainer {
    box-shadow: 1px 1px 4px 0px #00000040;
    padding: 20px;
    border-radius: 8px;
  
    .reportTitle {
      font-family: 'Hanken Grotesk', sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 26.06px;
      text-align: center;
      color: #000000;
      margin-bottom: 20px;
    }
  
    .sectionTitle {
      font-family: 'Hanken Grotesk', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 23.45px;
      text-align: left;
      color: #000000;
      margin-bottom: 10px;
    }
  
    .reportText {
      font-family: 'Hanken Grotesk', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.85px;
      text-align: left;
      color: #525252;
      margin-bottom: 20px;
    }
  
    .reportTable {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
  
      td {
        border: 1px solid #dddddd;
        padding: 8px;
      }
    }
  }
  