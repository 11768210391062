.firstsection-aboutus {
    height: calc(98vh - 86px);
    width: 100%;
    margin-top: calc(2vh + 86px);
    background: url("../../assets//Images//About/mainimage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.firstsection-aboutus>:last-child {
    display: flex;
    flex-direction: column;
    gap: 5%;
    width: 50%;
    height: 55%;
    padding-left: 3%;
    justify-content: space-evenly;
}

.firstsection-aboutus>:last-child>:first-child {
    font-size: 5rem;
    font-weight: 800;
    color: #58B135;
    line-height: 1.5;
}

.firstsection-aboutus>:last-child>p {
    font-size: 2.2rem;
    color: white;
    font-weight: 400;
    line-height: 1.5;

}

.firstsection-aboutus>:last-child>:nth-child(2) {
    width: 75%;
}

.firstsection-aboutus>:last-child>:nth-child(3) {
    width: 85%;
}

/* .about-mainimg {
    width: 100%;
    height: 100%;
    float: right;
    shape-outside: url("../../assets//Images//About/mainimage.jpg");
} */

.secondsection-aboutus {
    width: 100%;
    height: 100vh;
    background: #070707;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondsection-content {
    width: 80%;
    min-height: 50%;
    color: white;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
}

.secondsection-content>:first-child {
    font-size: 5rem;
    font-weight: 800;
    line-height: 1.5;

}

.secondsection-content>p {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 1.5;
}