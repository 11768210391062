.create-report-container {
    padding: 20px;
    font-family: 'Hanken Grotesk', sans-serif;
    display: flex;
    flex-direction: column;
    // flex: 1 1;
    overflow-y: auto;
  
    h2 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  
    .report-form {
      padding: 20px;
  
      .form-row {
        display: flex;
        gap: 20px;
        margin-bottom: 40px;
  
        .input-group {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
  
          label {
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.24px;
            text-align: left;
            color: #525252;
          }
  
          input {
            flex: 1;
            padding: 15px;
            border: 1px solid #E0E0E0;
            border-radius: 10px;
            font-size: 16px;
            box-shadow: 1px 1px 4px 0px #00000040;
          }
        }
      }
  
      .asset-table, .summary-table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
        border-spacing: 0;
        border: none;
  
        th, td {
          border: 1px solid #E0E0E0;
          padding: 20px 10px;
          text-align: left;
          font-size: 14px;
          color: #666666;
          position: relative;
        }
  
        th {
          background-color: #c3eeff;
          font-weight: 600;
          position: relative;
          border-top: none;
        }
  
        th:first-child,
        td:first-child {
          border-left: none;
        }
  
        th:last-child,
        td:last-child {
          border-right: none;
        }
  
        th:first-child {
          border-top-left-radius: 10px;
        }
  
        th:last-child {
          border-top-right-radius: 10px;
        }
  
        tbody tr:first-child th,
        tbody tr:first-child td {
          border-top: none;
        }

        th{
            color: #000000;
          }

  
          select {
            width: 100%;
            padding: 5px;
            border: none;
            color: #666666;
            background: var(--caret-icon) no-repeat right;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-size: 18px;
            white-space: wrap;
            overflow: hidden;
          }
        
        

        .bl_col{
            color:#1d5b05;

        }
      }

      .summary-table{
        width: 80%;
      }
  
      .add-row-button {
        display: flex;
        align-items: center;
        gap: 10px;
        background: none;
        border: none;
        color:#666666;
        cursor: pointer;
        margin-bottom: 40px;
        border: 1px solid #58B135;
        padding: 8px 12px;
        border-radius: 8px;
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.85px;
        text-align: left;
      }
  
      .expand-button {
        background: none;
        border: none;
        color: #666666;
        cursor: pointer;
        font-size: 28px;
        display: block;
        margin: 0 auto;
      }
    }
  
    .button-group {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      margin-top: 20px;
  
      button {
        padding: 10px 20px;
        border-radius: 16px;
        font-size: 16px;
        cursor: pointer;
      }
  
      .cancel-button {
        background-color: #ffffff;
        border: 1px solid #58B135;
        color: #58B135;
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 26.06px;
        text-align: center;

      }
  
      .save-button {
        background-color: #58B135;
        border: none;
        color: #ffffff;
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 26.06px;
        text-align: center;
      }
    }
  }
  