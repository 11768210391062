.sixth-section {
    width: 100%;
    min-height: 70vh;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.sixth-section>div {
    width: 90%;
    padding: 5vh var(--common-padding);
    height: 100%;
    /* display: grid;
    gap: 5%;
    grid-template-columns: repeat(3, 1fr); */
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: 20px auto;
    margin-bottom: 40px;
}