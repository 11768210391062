.profilepagesection {
    display: flex;
    align-items: start;
    padding: 0 4vw;
    padding-top: 4rem;
    justify-content: space-between;

    .hadletext {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-right: 30px;
    }

    .text-section-div {
        display: flex;
        align-items: flex-start;
        gap: 1.5rem
    }

    .rightside {
        height: 100vh;

        .box1 {
            border-radius: 15px;
            border: 1px solid #E5E5E5;
            background: #FFF;
            width: 356px;
            height: 119px;
            flex-shrink: 0;
            padding: 15px;
        }

        .box2 {
            border-radius: 15px;
            border: 1px solid #E5E5E5;
            background: #FFF;
            height: 309px;
            // height: 196px;
            flex-shrink: 0;
            padding: 15px;
            margin-top: 3rem;
        }

        .box3 {
            border-radius: 15px;
            border: 1px solid #E5E5E5;
            background: #FFF;
            width: 356px;
            height: calc(100vh - 62rem);
            flex-shrink: 0;
            padding: 15px;
            margin-top: 3rem;
        }

        .name {
            color: #999;
            margin-bottom: 2rem;
            font-family: Montserrat;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }








    .left {
        background: rgba(237, 237, 237, 0.37);
        border-radius: 15px;
        // border: 1px solid #E5E5E5;
        background: #FFF;
        border-radius: 15px;
        background: #FFF;
        height: calc(100vh - 11rem);
        overflow: scroll;
        width: calc(100vw - 600px);

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .leftbelowcontain {
        border-radius: 15px;
        border: 1px solid #E5E5E5;
        background: #FFF;
        padding: 30px;
        margin-top: 4rem;

        .text {
            margin-top: 2.5rem;
            color: #999;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 184.615% */

        }

        .tabs {
            display: flex;
            align-items: center;
            gap: 4rem;

            div {
                height: 31px;
                border-radius: 50px;
                color: #999;
                text-align: center;
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }
    }

    .leftcontainer {
        border-radius: 30px 30px 30px 30px;
        border: 1px solid #E5E5E5;
        background: white;
    }

    .content-sec {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        svg {
            margin-right: 7px;
        }

        div {
            color: #999;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }

    }

    .coverpicsection {
        width: 100%;
        border-radius: 30px 30px 0px 0px;
        position: relative;
        height: 190px;
        background-repeat: no-repeat;
        background-size: cover;
        flex-shrink: 0;
    }

    .buttonsection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2rem;
        margin-top: 3rem;
        padding-right: 30px;

        div {
            width: 106px;
            height: 31px;
            flex-shrink: 0;
            background: #58B135;
            border-radius: 50rem;
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .text-sec {
        padding-left: 30px;
        padding-top: 3rem;
        padding-bottom: 3rem;

        h1 {
            color: #262D36;
            font-family: Montserrat;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 1rem;
        }

        h3 {
            color: #110F0D;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 1rem;
        }

        h4 {
            color: #110F0D;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .photosection {
        border-radius: 138px;
        position: absolute;
        left: 25px;
        bottom: -68px;
        width: 138px;
        height: 138px;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50rem;
        }
    }
}


.menulistProfilepage {
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: start;
}