.productsAndAllocation {
  margin: 2rem 0;
  border: 1px solid #d9d9d9;
  display: flex;
  gap: 2rem;
  border-radius: 6px;

  & > div {
    width: 50%;
    padding: 2rem;
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;

    & > .title {
      font-size: 1.45rem;
      font-weight: 510;
      margin-bottom: 1.5rem;
    }

    table {
      border-spacing: 0;
      width: max-content;
    }

    thead {
      background: #d1d2d2;
    }
  }

  & > :nth-child(1) {
    width: 65%;
  }
  & > :nth-child(2) {
    width: 35%;
    border-left: 1px solid #d9d9d9;
  }
}
