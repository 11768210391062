.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 500px;
    max-width: 100%;
    text-align: center;
    height: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .checkbox-container {
    width: 75%;
    margin: 0 auto;
    padding-bottom: 35px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #B8B8B8;
    padding: 12px 0;
  }
  
  .checkbox-item input {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 1px solid #000000;
    background-color: #F5F5F5;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  
  .checkbox-item input:checked {
    background-color: #58B135;
  }
  
  .checkbox-item input:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
  }
  
  .checkbox-item label {
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.45px;
    color: #000000;
  }
  
  .share-button {
    background: #58B135;
    padding: 10px 24px;
    border-radius: 16px;
    color: #FFFFFF;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 26.06px;
    text-align: center;
    cursor: pointer;
    border: none;
    width: 235px;
    margin: 0 auto;
  }
  