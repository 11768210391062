.headerNav {
  height: 5.5rem;
  // background-color: #58B135;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 14rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.leftLogo {
  width: 10rem;
  height: 5rem;
  // background-color: white;
  // border-radius: 50%;
  object-fit: contain;
  // border: 1px solid #fff;
  padding: 1px;
  cursor: pointer;
}

.rightMenus {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: white;

  a {
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    text-decoration: none;
  }

  div {
    &:hover {
      transform: scale(1.2);
      transition: all ease-in-out 0.3s;
    }
  }
}
