.addvisorportalePage {
  //   height: 0;
  flex-grow: 1;
  // display: flex;
  flex-direction: column;
}

.descriptionSection {
  border-radius: 6px;
  background-color: white;
  border: 1px solid #e5e5e5;
  margin-top: 1rem;
  height: 150px;

  textarea {
    width: 100%;
    height: calc(100% - 35px);
    border: 0px;
    padding: 1rem;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.85px;
    text-align: left;
    color: #110f0d;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px !important;
    }
  }

  .title {
    background: #f5f5f5;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.85px;
    text-align: left;
    color: #110f0d;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    height: 32px;
    width: 100%;
  }
}

.vaultsMainAdvisor {
  height: calc(100vh - 97px);
  // padding-top: 2rem;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & > .headTab {
    display: flex;
    font-size: 0.85rem;
    font-weight: 550;
    color: #110f0d;
    height: 5%;
    padding: 0 3rem;
    background: #e5e5e540;
    border-bottom: 0.5px solid #e7e7e7;

    & > div {
      display: flex;
      align-items: center;
    }

    //each child differs

    & > :nth-child(1) {
      width: 25%;
    }

    & > :nth-child(2) {
      width: 22.5%;
    }

    & > :nth-child(3) {
      width: 22.5%;
    }

    & > :nth-child(4) {
      width: 30%;
    }
  }

  & > .bodyContainer {
    height: 100%;
    // overflow-y: scroll;
  }
}

.trackerDetailsWithdrawadvsor {
  .tableAdvisorList {
    border-bottom: 1px solid #d9d9d9;
    padding: 2rem 3rem;
  }

  & > div {
    display: flex;
    //   justify-content: space-between;
    //   gap: 1.5rem;
    padding: 35px 3rem;
    // border-radius: 10px;
    transition: all ease-in 0.4s;
    cursor: pointer;
    // white-space: nowrap;
    // margin: 15px 0;
    position: relative;

    & > .clickableDiv {
      position: absolute;
      inset: 0;
      background: transparent;
      border-radius: 10px;
    }
  }

  .eachSubDiv {
    display: flex;
    gap: 15px;
    overflow: hidden;
    align-items: center;
    padding: 0 0.5rem;

    // flex: 1;
    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 25%;
      // padding-right: 2rem;
    }

    &:nth-child(3) {
      width: 15%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 20%;
    }

    & > .roundedImg {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      // margin: auto 20px;
    }

    & > .squareImg {
      height: 50px;
      width: 50px;
      border-radius: 10px;
      // margin: auto 20px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      flex-wrap: wrap;

      & > .heading {
        display: flex;
        align-items: flex-start;
        font-size: 17px;
        font-weight: 550;
        margin-bottom: 4px;

        & > img {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
      }

      & > .date {
        font-size: 17px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .eachContentSubDiv {
      gap: 4px;

      & > :nth-child(1) {
        font-size: 1.6rem;
        font-weight: 510;
        color: #110f0d;
      }

      & > :nth-child(2) {
        font-size: 1.3rem;
        font-weight: 420;
        color: #110f0d;
      }
    }

    .eachPhoneNumber {
      font-size: 12px;
    }

    & > .hashBtn {
      position: relative;
      z-index: 2;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 5px;
      color: #343c5b;
      font-size: 15px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      cursor: pointer;
      transition: all ease-in 0.5s;
    }

    &:hover {
      //background: whitesmoke;

      & > .hashBtn {
        color: white;
        background: #343c5b;
      }
    }

    & > div {
      & > div {
        width: 100%;
        overflow: hidden;

        & > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis !important;
        }
      }
    }
  }

  & > .initialisedBtns {
    display: flex;
    padding: 20px;
    margin: 5px 0;
    justify-content: flex-end;

    & > .cancelBtn {
      padding: 0.75rem 2rem;
      //   background: #ea0f0f;
      border: 0.5px solid #e5e5e5;
      border-radius: 35px;
      color: white;
      width: max-content;
      font-weight: 550;
      color: #110f0d;
      background: white;
      transition: all ease-in 0.2s;
      cursor: pointer;

      &:hover {
        background: #343c5b;
        color: white;
      }
    }
  }
}

.clickaction {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  background: #fff;
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-bottom: 1.5rem;

  .titleaction {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.labeldetail {
  color: #110f0d;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: auto;
  padding: 0 3.5rem;
  border-radius: 35px;
  cursor: pointer;
  height: 42px;

  &:hover {
    transform: scale(1.02);
  }
}

.tableshowcontainer {
  &:hover {
    transform: scale(1.03);
  }
}

.tableproposal {
  h1 {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .tab-proposal {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .title-proposal {
    border-radius: 35px;
    border: 1px solid #e5e5e5;
    background: #fff;
    width: 191px;
    height: 36px;
    color: #110f0d;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .showinputsectionproposal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .divtshow {
    width: 48%;
  }

  .inputboxproposal {
    width: 100%;
    height: 52px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    background: #fff;
    color: #110f0d;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    padding-left: 13px;
  }

  .submit-btn {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 35px;
    background: #58b135;
    width: 191px;

    height: 36px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .titleinput {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 15px;
    margin-bottom: 0.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.underReiveSectionAdvisor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  background: #fff;
  padding: 0 1.4rem;
  margin-bottom: 2rem;

  .titleactionReviw {
  }

  .btnsContainer {
    display: flex;
    align-items: center;
    gap: 2rem;

    & > svg {
      font-size: 2.5rem;
      cursor: pointer;
      color: #d9d9d9;
    }

    .deleteProposalContainer {
      position: fixed;
      inset: 0;
      z-index: 1;

      & > .overviewContainer {
        position: absolute;
        inset: 0;
        z-index: 2;
        background: #00000080;
      }

      & > .deleteContainer {
        position: absolute;
        z-index: 2;
        width: 500px;
        background: white;
        border-radius: 8px;
        // aspect-ratio: 1.2/1;
        padding: 2rem 1.2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 3rem;
        justify-content: center;
        align-items: center;

        & > img {
          height: 7rem;
        }

        & > .title {
          font-size: 1.6rem;
        }

        & > .btnsContainer {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: center;
          align-items: center;

          & > div {
            padding: 1.2rem 2rem;
            border-radius: 7px;
            font-size: 1.2rem;
            font-weight: 500;
            cursor: pointer;
            width: 150px;
            text-align: center;
          }

          & > :nth-child(1) {
            background: #58b135;
            color: white;
          }

          & > :nth-child(2) {
            background: #d1d7e1;
          }
        }
      }
    }
  }

  .underReview {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 10px;
    background: #ff9100;
    width: 169px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.EZDrawer .EZDrawer__container {
  height: calc(100% - 12%) !important;
  bottom: 0 !important;
  top: auto !important;
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.1) !important;
  border: 0.5px solid #e7e7e7;
  border-top: 0px solid #e7e7e7;
}

.addnewclass {
  padding: 2rem;

  .areyouseture {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1.5rem;
  }

  .card-bottomclass {
    height: calc(100vh - 3rem);
    overflow-x: scroll;
  }

  .Submit-go {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    height: 67px;
    background: #fff;
    justify-content: center;
    margin-bottom: 1rem;
    border-radius: 40px;
    color: #110f0d;
    cursor: pointer;
    text-align: center;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: 1px solid #e5e5e5;

    &:hover {
      transform: scale(1.03);
    }
  }

  .Submit {
    border-radius: 40px;
    display: flex;
    align-items: center;
    margin-top: 10rem;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    width: 100%;
    height: 67px;
    background: #58b135;
    justify-content: center;
    margin-bottom: 1rem;

    &:hover {
      transform: scale(1.03);
    }
  }

  input {
    border-radius: 35px;
    border: 1px solid #e5e5e5;
    background: #fff;
    width: 100%;
    height: 56px;
    color: #18191d;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  textarea {
    border-radius: 35px;
    border: 1px solid #e5e5e5;
    background: #fff;
    width: 100%;
    height: 266px;
    color: #18191d;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 20px;
    margin-bottom: 2rem;
  }

  .nameofmutual {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .boxselext {
    display: flex;
    align-items: center;

    &:hover {
      transform: scale(1.03);
    }

    cursor: pointer;
    margin-bottom: 2rem;

    &:nth-last-child(1) {
      margin-bottom: 0rem;
    }

    img {
      margin-right: 10px;
      width: 37.148px;
      height: 34px;
    }
  }

  .mutualid {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .selectsected {
    width: 100%;
    border-radius: 35px;
    border: 1px solid #e5e5e5;
    background: #fff;
    color: #18191d;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: normal;
    padding-left: 20px;
    padding-right: 20px;
    height: 56px;
    cursor: pointer;
    // margin-top: 10rem;
  }

  .selectbox {
    width: 100%;
    border-radius: 35px;
    border: 1px solid #e5e5e5;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 2px;
  }

  .upload-txxt {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.5rem;
  }

  .card-bottomclass {
  }

  .name {
    width: 100%;
    height: 58px;
    flex-shrink: 0;
    border-radius: 35px;
    border: 1px solid #e7e7e7;
    background: #fff;
    padding-left: 25px;
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-bottom: 1.5rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
    }
  }

  .addnew {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .toptitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
  }
}

.plantable {
  display: flex;
  justify-content: space-between;
  align-items: self-start;

  .leftpanb {
    display: flex;

    align-items: self-start;
  }

  .rightsidetable {
    display: flex;

    align-items: self-start;
  }

  .plancurrecy {
    color: #110f0d;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid #e5e5e5;
    background: #fff;
    height: 51px;
    flex-shrink: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    border-left: 0px solid;
    display: flex;
    align-items: center;
  }

  .monthaccomplish {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0 1.2rem;
    height: 51px;
    flex-shrink: 0;
    border-radius: 10px 0px 0px 0px;
    border: 1px solid #e5e5e5;
    background: #fff;
    display: flex;
    align-items: center;
  }

  .monthplanb {
    // width: 325px;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    background: #110f0d;
  }

  .planbcurrenyvalue {
    border-left: 0px solid;
    height: 51px;
    flex-shrink: 0;
    border-radius: 0px 10px 0px 0px;
    border: 1px solid #e5e5e5;
    background: #fff;
    color: #110f0d;
    text-align: center;
    font-family: Montserrat;
    padding: 0 1.2rem;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
  }

  .planName {
    color: #110f0d;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px 0px 0px 0px;
    border: 1px solid #e5e5e5;
    background: #fff;
    padding-left: 2rem;
    padding-right: 4rem;
    display: flex;
    align-items: center;
    // justify-content: center;
    height: 51px;
    flex-shrink: 0;
  }
}

.plabbtable-section {
  width: 100%;
  overflow: hidden;
  margin-top: 2.5rem;

  // margin-bottom: 2.5rem;
  .text-handle {
    color: #110f0d;
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: 100%;
  }

  .dropdwonlist {
    border-radius: 8px;
    background: #f5f5f5;
    padding: 1rem;
    position: absolute;
    top: 65px;
    width: 90%;
    left: 0;
    margin: auto;
    right: 0;
    z-index: 1;
    white-space: break-spaces;

    .listitemName {
      display: flex;
      align-items: center;
      color: #110f0d;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 1rem;

      &:nth-last-child(1) {
        margin-bottom: 0rem;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      &:hover {
        transform: scale(1.03);
      }
    }
  }

  .addlalbep {
    background: #1d5b05;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: auto;
    margin-bottom: 1.5rem;
    padding: 0 3.5rem;
    border-radius: 35px;
    margin-top: 1.5rem;
    cursor: pointer;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: scale(1.03);
    }
  }

  .toggledrop {
    position: fixed;
    z-index: 9999;
    width: 100%;
  }

  table {
    border: 1px solid #e5e5e5;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e5e5e5;
    background: #fff;
    overflow-x: auto;
    display: block;
    background: white;

    &::-webkit-scrollbar {
      display: none;
    }

    .underReview {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      border-radius: 10px;
      background: #ff9100;
      width: 169px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 30px;
      // height: 100%;
      padding-left: 18px;
      color: #110f0d;
      font-family: Montserrat;
      font-size: 14px;
      padding: 1rem;
    }
  }

  tr {
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;

    &:nth-last-child(1) {
      border-bottom: 0px solid #e5e5e5;
    }
  }

  th {
    padding: 1rem 2rem;
    white-space: nowrap;
    border-right: 1px solid #e5e5e5;
    text-align: center;

    // background: #666;
    &:nth-last-child(1) {
      border-right: 0px solid #e5e5e5;
    }
  }

  .dropdown-planb {
    border-radius: 35px;
    background: #f5f5f5;
    height: 32px;
    width: 100%;
    color: #110f0d;
    font-family: Montserrat;
    padding: 0 0.7rem;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-left: 15px;
    }
  }

  td {
    border-right: 1px solid #e5e5e5;
    padding: 0.5rem 2rem;
    white-space: nowrap;
    height: 42px;
    position: relative;
    align-items: center;

    // background: #999;
    &:nth-last-child(1) {
      border-right: 0px solid #e5e5e5;
    }

    // div{

    //     &:nth-child(2){
    //         position: fixed;
    //         z-index: 9999;
    //         width: 100%;
    //     }
    // }
  }
}

.pagemainflex {
  //   display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;

  .rightsidedatashow {
    width: calc(100vw - 77px);
  }
}

.labelplantext {
  height: 32px;
  border: 1px solid #d9d9d9;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: #110f0d;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  width: 109px;
  transition: all linear 0.3s;

  &:hover {
    background: #58b135;
    color: white;
  }
}

.tableAdvisorList {
  &:hover {
    transform: scale(1.03);
  }
}
