.advisor-client-list-rightsidedatashow {
    width: calc(100vw - 77px);
    overflow-y: auto;
    padding-bottom: 100px;
    height: 100%;

    .client-main{
      display: flex;
      gap: 50px;
      padding: 0 40px;

      .client-child{
        &:first-of-type{
          width: 65%;
        }

        &:last-of-type{
          width: 20%;
        }
      }
    }

  }
  