.calculator-section {
    width: 100%;
    height: 100vh;
    background: #ffffff;
    padding: 2vw var(--common-padding);
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.calculator-section-left {
    width: 40%;
    height: 100%;
    border-radius: 5%;
    padding: 3%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 0.5px solid #EFEFEF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.calculator-section-right {
    width: 50%;
    height: 100%;
    padding: 3%;
    background: white;
    border-radius: 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    gap: 3vw;
}

.calculator-section-left>:first-child {
    width: 100%;
    height: 3rem;
}

.calculator-section-left>:first-child img {
    height: 100%;
}

.calculator-right-categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.5vw;
    height: 15vw;
}

.right-categories {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    min-height: 7vw;
}

.calculator-right-second-heading>:nth-child(2) {
    font-weight: 400;
    font-size: 1.5rem;
    color: rgb(125, 125, 125);
}

.calculator-right-second-heading>:first-child {
    font-weight: 700;
    font-size: 2rem;
    line-height: 185%;
    color: rgb(125, 125, 125);

}

.calculator-right-second-heading>:last-child {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 2rem;
    font-weight: 800;
    color: #58B135;
}

.calculator-right-second-heading {
    position: relative;
}

.calculator-right-heading>:first-child {
    font-size: 2.5rem;
    font-weight: 800;
    color: rgb(125, 125, 125);
}

.calculator-right-heading>:last-child {
    font-weight: 400;
    font-size: 1.5rem;
    color: rgb(125, 125, 125);
    line-height: 205%;
}

.selected-card {
    /* height: 7vw; */
    min-height: max-content;
    width: 100%;
    padding: 5%;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.selected-card-arrow {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 2rem;
    font-weight: 800;
    color: #58B135;
}

.selected-card>:first-child {
    font-size: 1.8rem;
    font-weight: 800;
    color: rgb(125, 125, 125);
}

.selected-card>:first-child>:first-child {
    color: #58B135;
    margin-right: 10px;
}

.selected-card-options {
    text-align: center;
    outline: none;
    border: none;
    background: transparent;
    padding: 5px;
    border-bottom: 2px solid rgb(125, 125, 125);
}

.selected-card select {
    margin-top: 10px;
}

.selected-card>:nth-child(3) {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.selected-card>:nth-child(3)>div {
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
}

.calculator-right-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid red; */
    height: 8%;
}

.calculator-right-btn>div {
    /* width: 20%; */
    height: 80%;
    font-size: 1.5rem;
    color: #110F0D;
    font-weight: 600;
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    border-radius: 1.5rem;
    border: 1px solid #E5E5E5;
}

.calculator-right-btn>div>a {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 2rem 4rem;
}

.calculator-section-left-markers {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 10px;
}

.calculator-section-left-markers div {
    position: relative;
    font-size: 2rem;
    color: rgb(125, 125, 125);
    font-weight: 600;
    text-decoration: none;
    width: 25%;
    padding-left: 5px;
    cursor: pointer;
}

.calculator-section-left-markers a {
    position: relative;
    font-size: 2rem;
    color: rgb(125, 125, 125);
    font-weight: 600;
    text-decoration: none;
    /* width: 25%; */
    padding-left: 5px;
    cursor: pointer;
    display: block;
}


.calculator-section-left>:nth-child(2)>:last-child {
    font-size: 2rem;
    font-weight: 600;
    color: #110F0D;
    margin-top: 3rem;
    line-height: 1.9;
}

.calculator-section-left-markers #marker {
    position: absolute;
    height: 3px;
    left: 0;
    width: 0;
    background: #58B135;
    bottom: -8px;
    transition: all ease-in 0.5s;
    border-radius: 4px;
    z-index: 1;
}

.calculator-section-left-markers #markerunder {
    position: absolute;
    height: 3px;
    left: 0;
    width: 100%;
    background: #EFEFEF;
    bottom: -8px;
    transition: all ease-in 0.5s;
    border-radius: 4px;
}