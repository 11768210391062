.leftSideMenu {
    width: 77px;
    height: 100vh;
    background: #FFF;
    border-right: 1px solid #E5E5E5;


    svg {
        cursor: pointer;

        &:hover {
            transform: scale(1.3);
        }
    }

    .logoMenu {
        height: 77px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #E5E5E5;
        margin-bottom: 2.5rem;


    }

    .bottmlistmenu {
        height: calc(100vh - 77px);
        // overflow: scroll;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;



    }

    .itemlistimg {
        border-radius: 5px;
        // border: 1px solid #E5E5E5;

        background: #FFF;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-bottom: 2.5rem;

        &:hover {
            .showdathover {
                display: flex;
                z-index: 99;
            }
        }


        .showdathover {
            border-radius: 35px;
            border: 1px solid #E5E5E5;
            background: #FFF;
            position: absolute;
            height: 28px;
            display: none;
            align-items: center;
            justify-content: center;
            padding: 0 3rem;
            left: 68px;
            color: #110F0D;
            text-align: center;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        svg {
            height: 25px;
        }
    }
}