@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&family=Roboto:wght@400;500;700&display=swap");

html {
  font-family: "Montserrat";
  font-size: 62.5%;
  --common-padding: 8vw;
}

:root {
  --main-color: #58b135;
  --main-color-light: #58b1354f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flexContainers {
  display: flex;
  height: calc(100% - 65px);
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 40%;
  }
}

@media screen and (max-width: 700px) {
  html {
    font-size: 36%;
    --common-padding: 6vw;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 32%;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 70%;
  }
}

@media (min-width: 2100px) {
  html {
    font-size: 80%;
  }
}
