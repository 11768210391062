.container {
  background-color: white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1000;
  min-width: 60vw;
  min-height: 50vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  // margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  //   background-color: yellow;
  height: fit-content;
}

.header-title {
  width: fit-content;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 22px;
}
.cancel-btn {
  border: none;
  background-color: none;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cancel-button {
  background: none;
  border: none;
  cursor: pointer;
  img {
    width: 25px;
    height: auto;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px; 
  max-width: 60%;
  margin: auto;
  min-width: 60%;
}

.filter-row {
  display: flex;
  flex-direction: column;
}

.btnContainer {
  height: fit-content;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.btnContainer button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  cursor: pointer;
}

.btnContainer button:first-of-type {
  background-color: white;
  color: #1d5b05;
  border: 0.66px solid gray;
  border-radius: 8px;
  cursor: pointer;
}

.btnContainer button:last-of-type {
  background-color: #58B135;
  color: white;
  border-radius: 8px;
}

.btnContainer button:disabled {
  background-color: #666666;
  cursor: not-allowed;
}
