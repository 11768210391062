.advisorCard {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #B8B8B8;

    img{
        border-radius: 50%;
        object-fit: cover;
    }

    .advisorInfo {
        margin-left: 20px;
    }

    .advisorName {
        font-size: 18px;
        font-weight: 600;
        color: #000000;
        line-height: 23.45px;
        padding-bottom: 6px;
    }

    .advisorId {
        font-size: 14px;
        line-height: 18.24px;
        color: #7A7A7A;
        font-weight: 600;
        padding-bottom: 6px;
    }

    .advisorExperience {
        font-size: 14px;
        line-height: 18.24px;
        color: #7A7A7A;
        font-weight: 400;
        padding-bottom: 6px;
    }

    .advisorPlace {
        font-size: 12px;
        line-height: 15.64px;
        color: #7A7A7A;
        font-weight: 400;
        padding-bottom: 4px;
    }

    .sendRequestButton {
        margin: auto 0;
        margin-left: auto;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid #02364A;
        background-color: transparent;
        cursor: pointer;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.59px;
        width: 154px;
    }
}