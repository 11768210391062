.fourth-section {
    width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fourth-section>div {
    padding: 5vh var(--common-padding);
    display: flex;
    height: 100%;
    width: 100%;
    padding-right: 0;
    align-items: center;
}

.fourth-section>div>div {
    width: 65%;
    height: 100%;
}

.fourth-section>div>:last-child {
    width: 35%;
    background-color: #58B135;
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.5;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5%;
    gap: 2rem;
    height: 100%;
}

.fourth-section>div>:first-child {
    font-size: 4rem;
    height: 100%;
    font-weight: 800;
    line-height: 1.5;
    color: black;
    padding: 5%;
    gap: 2rem;
}

.blueText {
    color: #58B135;
}

@media screen and (max-width:700px) {
    .fourth-section>div {
        flex-direction: column;
        padding: 5vh 0;
    }

    .fourth-section>div>div {
        width: 100%;
    }

    .fourth-section>div>:last-child {
        width: 100%;
    }

}