.allAdvisorLeads {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > .tableAdvisorList {
    border-bottom: 1px solid #d9d9d9;
    padding: 2rem 3rem;
    display: flex;
    width: 100%;

    .eachSubDiv {
      display: flex;
      gap: 15px;
      overflow: hidden;
      align-items: center;
      padding: 0 0.5rem;

      // flex: 1;
      &:nth-child(1) {
        width: 25%;
      }

      &:nth-child(2) {
        width: 25%;
        // padding-right: 2rem;
      }

      &:nth-child(3) {
        width: 15%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 20%;
      }

      & > .roundedImg {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        // margin: auto 20px;
      }

      & > .squareImg {
        height: 50px;
        width: 50px;
        border-radius: 10px;
        // margin: auto 20px;
      }

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        flex-wrap: wrap;

        & > .heading {
          display: flex;
          align-items: flex-start;
          font-size: 17px;
          font-weight: 550;
          margin-bottom: 4px;

          & > img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
          }
        }

        & > .date {
          font-size: 17px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .eachContentSubDiv {
        gap: 4px;

        & > :nth-child(1) {
          font-size: 1.6rem;
          font-weight: 510;
          color: #110f0d;
        }

        & > :nth-child(2) {
          font-size: 1.3rem;
          font-weight: 420;
          color: #110f0d;
        }
      }

      .eachPhoneNumber {
        font-size: 12px;
      }

      & > .hashBtn {
        position: relative;
        z-index: 2;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        border-radius: 5px;
        color: #343c5b;
        font-size: 15px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
        cursor: pointer;
        transition: all ease-in 0.5s;
      }

      &:hover {
        //background: whitesmoke;

        & > .hashBtn {
          color: white;
          background: #343c5b;
        }
      }

      & > div {
        & > div {
          width: 100%;
          overflow: hidden;

          & > div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis !important;
          }
        }
      }

      .labelplantext {
        height: 32px;
        border: 1px solid #d9d9d9;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 26px;
        text-align: center;
        color: #110f0d;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 7px;
        width: 109px;
        transition: all linear 0.3s;

        &:hover {
          background: #58b135;
          color: white;
        }
      }
    }
  }
}
