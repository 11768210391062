.carouselWrapper {
  max-width: 90%;
  margin: auto;
}

.testimonial {
  padding: 20px;
  border-radius: 0px 15px 0px 15px;
  color: white;
  text-align: center;
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
  margin: 0 4rem; /* Adding gap between testimonials */
  padding-bottom: 20px;
}

.selected {
  opacity: 1;
  transform: scale(1);
}

.avatar {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 2rem;
}

h3 {
  margin: 1rem 0;
}

/* Ensure the non-selected testimonials are styled properly */
.carousel .slide {
  opacity: 0.5;
  transform: scale(0.8);
  height: 15.5rem;
}

.carousel .slide.selected {
  opacity: 1;
  transform: scale(1);
  height: 19.625rem;
}

.carousel .control-dots .dot {
  background: #b8b8b8;
  box-shadow: none;
}

.carousel .control-dots .dot.selected {
  background: #2087b0; /* Change this to the color you prefer for the selected dot */
}

.carousel .control-dots {
  position: absolute;
  bottom: -30px; /* Adjust as needed */
  width: 100%;
  text-align: center;
}

.carousel .control-dots .dot {
  background: #ccc; /* Default color for inactive dots */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px;
  cursor: pointer;
}
