.advisor-list-pagemainflex {
    display: flex;
    align-items: center;
    height: 100vh;
    overflow: hidden;


    .advisor-list-rightsidedatashow {
        width: calc(100vw - 77px);

        .advisor-list-searchProfileContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            margin: 30px 0 30px;
        }

        .advisor-list-adInivial {
            background: #FFFFFF;
            height: calc(100vh - 74px);
            overflow: scroll;
            padding: 0 2vw;

            .advisorSections {
                margin-bottom: 120px;
                width: 90%;

                .advisorList {
                    // margin-bottom: 120px;
                    // padding-top: 32px;
                    padding-top: 24px;
                }

                .yourAdvisors,
                .otherAdvisors {
                    padding-top: 32px;
                }

                .sectionTitle {
                    font-family: 'Hanken Grotesk', sans-serif;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 26.06px;
                    text-align: left;
                    color: #000000;
                    margin-bottom: 15px;
                }

            }

        }
    }
}