.doublearrow {
    position: absolute;
    top: -1.2%;
    left: -1.3%;
    background: #58B135;
    padding: 2.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.8s;
}

.doublearrow:hover {
    padding: 3.5rem;
}