.advisor-profile-pagemainflex {
  // display: flex;
  align-items: start;
  height: 100vh;
  overflow: hidden;

  .advisor-profile-rightsidedatashow {
    width: calc(100vw - 77px);
    overflow-y: auto;
    padding-bottom: 100px;
    height: 100%;
    padding-top: 20px;

    .topBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 20px;
      padding: 20px 35px;

      .backButton {
        background: white;
        border: none;
      }
    }

    .searchProfileContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      margin: 20px 0 30px;

      .searchBoxContainer {
        display: flex;
        align-items: center;
        border-radius: 8px;
        flex-grow: 1;
        padding: 10px 10px;
        background-color: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3019607843),
          0px 1px 8px 2px rgba(0, 0, 0, 0.1490196078);
        width: 520px;
        max-width: 520px;

        .searchIcon {
          color: #7a7a7a;
          width: 20px;
          height: 20px;
        }

        .searchInput {
          font-family: "Rubik", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.59px;
          text-align: left;
          color: #7a7a7a;
          border: none;
          outline: none;
          margin-left: 10px;
          flex-grow: 1;

          &::placeholder {
            color: #7a7a7a;
          }
        }
      }

      .profileIconsContainer {
        display: flex;
        align-items: center;
        gap: 30px;

        .shareIcon {
          width: 20px;
          height: 20px;
          object-fit: contain;
          cursor: pointer;
        }

        .profileIcon {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }

    .advisor-profile-main {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;
      padding: 0 20px;

      .advisor-profile-parent {
        width: 70%;
      }

      .advisor-profile-parent-1 {
        width: 30%;
        box-shadow: 0px 1px 3px 0px #0000004d;
        box-shadow: 0px 1px 8px 2px #00000026;
        border-radius: 8px;
      }
    }

    .advisor-profile-content {
      // padding: 0 3vw;
      border-radius: 8px;

      .custom-flex {
        width: 50%;
        padding-top: 40px;
      }

      .profile-info {
        display: flex;
        align-items: flex-start;
        box-shadow: 0px 1px 8px 2px #00000026;
        border-radius: 8px;
        padding: 20px;

        .profile-image {
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }

        .profile-details {
          margin-left: 20px;
          width: calc(100% - 120px);

          .about-us {
            padding-top: 20px;
            padding-right: 10px;

            h6 {
              font-family: "Hanken Grotesk", sans-serif;
              font-size: 14px;
              font-weight: 600;
              line-height: 18.24px;
              text-align: left;
              color: #000000;
              margin-bottom: 8px;
            }

            p {
              font-family: "Hanken Grotesk", sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 18.24px;
              text-align: left;
              color: #7a7a7a;
            }
          }

          .profile-heading {
            font-family: "Hanken Grotesk", sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 26.06px;
            color: #000000;
            padding-bottom: 4px;
          }

          .profile-expertise {
            font-family: "Hanken Grotesk", sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: 18.24px;
            color: #666666;
            padding-bottom: 6px;
            text-transform: capitalize;
          }

          .profile-experience {
            font-family: "Hanken Grotesk", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.24px;
            color: #7a7a7a;
          }

          .profile-place {
            font-family: "Hanken Grotesk", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 15.64px;
            color: #7a7a7a;
            text-decoration: none;
            padding-bottom: 4px;
          }
        }
      }

      .products-services {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        width: 100%;
        margin-top: 20px;

        .services,
        .products,
        .testimonial-parent {
          margin-bottom: 20px;
          box-shadow: 0px 1px 8px 2px #00000026;
          border-radius: 8px;
          padding: 15px 20px;

          .section-heading {
            font-family: "Hanken Grotesk", sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: 26.06px;
            color: #000000;
            padding-bottom: 15px;
          }

          ul {
            padding-left: 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
              font-family: "Hanken Grotesk", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 20.85px;
              color: #666666;
              margin-bottom: 10px;
              width: 50%;
              text-transform: capitalize;
            }
          }

          .testimonials {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .testimonial {
              padding: 8px;
              border-radius: 8px;
              border: 1px solid #8f8f8f;
              margin-bottom: 2px;

              .testimonial-content {
                font-family: "Hanken Grotesk", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 20.85px;
                color: #666666;
              }
            }
          }
        }
      }

      .section-heading {
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 26.06px;
        color: #000000;
      }
    }

    .advisor-profile-parent-1 {
      // padding: 0 2vw;
      padding: 0 20px;
      // margin-top: 20px;

      .videos {
        padding: 12px 0;

        .section-heading {
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 26.06px;
          color: #000000;
        }

        .video-flex {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .video {
            display: flex;
            flex-direction: column;
            //   align-items: center;
            margin-top: 10px;

            .video-image {
              width: 100%;
              // height: 100%;
              height: 155px;
              border-radius: 12px;
              object-fit: cover;
            }

            .video-title {
              font-family: "Hanken Grotesk", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 20.85px;
              color: #525252;
              margin-top: 8px;
            }

            .video-link {
              font-family: "Hanken Grotesk", sans-serif;
              font-size: 12px;
              font-weight: 400;
              line-height: 15.64px;
              color: #525252;
              text-decoration: none;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}

.flex_class {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;

    &:hover {
      cursor: pointer;
    }
  }
}

.logoNavbarLeft {
  // width: 216px;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 40px;
  justify-content: space-between;
  // border-right: 1px solid #d9d9d9;

  .newBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    background: #58b13526;
    color: #58b135;
    font-weight: 510;
    font-size: 1.2rem;
    border-radius: 6px;
    border: 0.5px solid #58b135;
    cursor: pointer;
    transition: all linear 0.2s;

    &:hover {
      background: #58b135;
      color: white;
    }
  }
}

.navBtnsContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
  padding-right: 2rem;

  .newBtn,
  .newBtnTransparent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    background: #58b13526;
    color: #58b135;
    font-weight: 510;
    font-size: 1.2rem;
    border-radius: 6px;
    border: 0.5px solid #58b135;
    cursor: pointer;
    transition: all linear 0.2s;

    &:hover {
      background: #58b135;
      color: white;
    }
  }

  .newBtnTransparent {
    background: transparent;
    color: #5b5b5b;
    border-color: #d9d9d9;
  }
}
