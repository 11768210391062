.twelfth-section {
    min-height: 80vh;
    width: 100%;
    padding: 5vh var(--common-padding);
    display: flex;
    justify-content: center;
    align-items: center;
}

.twelfth-section>div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5vh;
}

.twelfth-section>div>:first-child {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.5;
    color: #0A0B0B;
    text-align: center;
}

.twelfth-section>div p {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0A0B0B;
    text-align: center;
    margin: 0 auto;
}

.twelfth-section>div>:nth-child(2) {
    width: 60%;
}

.twelfth-section>div>:nth-child(3) {
    width: 50%;
}

.twelfth-last {
    width: 40%;
    margin: 0 auto;
}

.twelfth-last>:first-child {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.5;
    color: #0A0B0B;
    text-align: center;
}

.twelfth-last>:last-child {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    gap: 10px;
}

.twelfth-last-btn {
    width: max-content;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    padding: 1.5rem 2rem;
    background: #58B135;
    border: 0.5px solid #58B135;
    border-radius: 100px;
    cursor: pointer;
    white-space: nowrap;
}