.advisor-chat-pagemainflex {
  // display: flex;
  align-items: start;
  height: 100vh;
  overflow: hidden;
}

.advisor-chat-advisor-rightsidedatashow {
  width: calc(100vw - 77px);
  overflow-y: auto;
  padding: 20px 30px;
  // flex: 1 1;
  position: relative;
}

.article-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Color-border-border, #e3e3e3);
  margin-bottom: 40px;
}

.tab-container {
  display: flex;
  gap: 20px;
}

.tab {
  padding: 16px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: var(--Color-text-text, #303030);
  background: none;
  border: none;
  cursor: pointer;
}

.tab.active {
  font-weight: 600;
  border-bottom: 2px solid #303030;
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 8px;
}

.search-icon {
  color: #7a7a7a;
  margin-right: 8px;
  font-size: 18px;
}

.search-input {
  border: none;
  outline: none;
  font-family: Rubik, sans-serif;
  font-size: 18px;
}

.search-input::placeholder {
  color: #7a7a7a;
}

.article-main {
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  // height: 80%;

  // .article-list{
  //     width: 90%;
  // }

  .article-list1 {
    width: 10%;
  }
}

.article-list {
  // display: flex;
  // flex-wrap: wrap;
  // gap: 50px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  // justify-content: space-between;
  // align-items: center;
  // height: max-content;
}

.article-card {
  // flex: 1 1 calc(33.333% - 20px);
  background: #ecfaff;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 6px;
  transition: all linear 0.3s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.article-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.article-content {
  padding: 16px;
}

.article-title {
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23.45px;
  color: #000000;
  margin-bottom: 8px;
}

.article-description {
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.64px;
  color: #3d3d3d;
  margin-bottom: 8px;
}

.read-more {
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.64px;
  color: #000000;
}

.edit-icon {
  // position: absolute;
  // top: 8px;
  // right: 8px;
  width: 32px;
  height: 32px;
  object-fit: cover;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.create-new-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 14px 18px;
  background-color: #58b135;
  color: #fff;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 4px 0px #00000033;
  // margin-left: auto;
  // margin-right: 30px;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.create-new-button:hover {
  background-color: #2589b0;
}
