.sidebar {
  width: 260px;
  // background: #58b135;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  left: 0;
  top: 0;
  z-index: 1000;
  border-right: 1px solid #d9d9d9;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  &-logo {
    // width: 28px;
    // height: 28px;
    object-fit: cover;
    margin-right: 1px;
    margin-bottom: 5px;
    display: block;
  }

  &-title {
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 26.06px;
    text-align: left;
    color: #110f0d;
  }

  &-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin-bottom: 20px;

    &-top,
    &-bottom {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 5px 8px;
      border-radius: 8px;
      cursor: pointer;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 20.85px;
      text-align: left;
      color: #110f0d;
      margin: 6px 0;
      transition: background-color 0.3s ease, border-color 0.3s ease;

      a,
      .sidebar-link {
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none;
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid transparent;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
          border-color: #ffffff;
        }

        &.active {
          background-color: rgba(255, 255, 255, 0.2);
          border-color: #ffffff;
        }
      }

      & .sidebar-icon {
        margin-right: 10px;
      }
    }
  }
}
