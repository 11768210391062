.advisor-chat-page-main-flex {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 10px;
}

.backButton {
  border: none;
  background: none;
  cursor: pointer;
}

.profileName {
  font-size: 24px;
  font-weight: bold;
  padding-left: 20px;
}

.client-goal-main {
  display: flex;
  flex: 1;
  overflow-y: auto;
  width: 85%;
}

.client-goal-left,
.client-goal-right {
  flex: 1;
  padding: 30px;
}

.client-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.client-goal-name,
.client-goal-duration {
  margin-bottom: 70px;
}

.client-goal-name .label,
.client-goal-duration .label {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.45px;
  color: #525252;
  display: block;
  margin-bottom: 8px;
}

.client-goal-name .value-input,
.client-goal-duration .value-input {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.45px;
  color: #000000;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  box-shadow: 1px 1px 4px 0px #00000040;
}

.client-goal-saved-amount {
  margin-top: 20px;
}

.client-goal-saved-amount h4 {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.45px;
  text-align: left;
  color: #525252;
  padding-bottom: 8px;
}

.client-goal-saved-amount table {
  width: 100%;
  border-collapse: collapse;
}

.client-goal-saved-amount th {
  background: #58B135;
  width: 210px;
  height: 46px;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.24px;
  color: #000000;
  text-align: left;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #8F8F8F;
}

.client-goal-saved-amount th:first-of-type {
  border-top-left-radius: 10px;
  border-right: 1px solid #8F8F8F;
}

.client-goal-saved-amount th:last-of-type {
  border-top-right-radius: 10px;
}

.client-goal-saved-amount td {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.24px;
  text-align: left;
  padding: 20px 20px 30px;
  border-bottom: 1px solid #E0E0E0;
}

.client-goal-saved-amount td:first-child {
  color: #1d5b05;
  border-right: 0.50px solid #E0E0E0;
}

.client-goal-saved-amount td:last-child {
  color: #666666;
}

.report-section .report-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.report-section .report-item span:first-of-type {
  text-decoration: underline;
  color: #000000;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.45px;
  text-align: left;
}

.report-section .status {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.45px;
  text-align: left;
}

.report-section .status.rejected {
  color: #f01212;
}

.report-section .status.approved {
  color: #0d9c13;
}

.report-section .status.in-progress {
  color: #aaa416;
}

.create-report-button {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: #ffffff;
  background: #58B135;
  box-shadow: 0px 2px 4px 0px #00000033, 0px 1px 10px 0px #0000001f, 0px 4px 5px 0px #00000024;
  border-radius: 50px;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: auto;
}

.client-goal-right .report-parent h4 {
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.45px;
  text-align: left;
  color: #525252;
  padding-bottom: 8px;
}

.client-goal-right .report-section {
  box-shadow: 1px 1px 4px 0px #00000040;
  padding: 20px;
  border-radius: 10px;
  height: 300px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}