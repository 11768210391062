.main-nav {
    display: flex;
    height: 10rem;
    padding: 2rem var(--common-padding);
    justify-content: space-between;
}

.nav-logo {
    width: 30%;
    padding: 0.5rem;
}

.nav-logo img {
    height: 100%;
    max-width: 100%;
}

.main-nav-navigations {
    list-style: none;
    display: flex;
    width: 50%;
    font-size: 1.5rem;
    font-weight: 600;
    color: #110F0D;
    /* justify-content: center; */
    justify-content: space-evenly;
    align-items: center;
}

.main-nav-navigations>li {
    transition: all ease 0.5s;
    cursor: pointer;
}

.main-nav-navigations>li>a {
    text-decoration: none;
    color: inherit;
}

.main-nav-navigations>li:hover {
    color: black;
}

.main-nav-btn {
    padding: 0.5rem 1.5rem;
    color: white;
    font-weight: 700;
    background: #58B135;
    border: 0.5px solid #58B135;
    border-radius: 10rem;
}

.main-nav-btn:hover {
    /* scale: 1.2; */
    border: 0.5px solid #110F0D;
    color: #110F0D;
    background: white;
}

.main-nav-menu {
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    color: #58B135;
    z-index: 3;
}

@media screen and (max-width:750px) {
    .main-nav-navigations {
        display: none;
    }

    .main-nav-menu {
        display: flex;
    }
}

@media screen and (max-width:1100px) {
    .main-nav-navigations {
        width: 70%;
    }
}