.advisor-profile-rightsidedatashow {
    width: calc(100vw - 77px);
    overflow-y: auto;
    padding-top: 20px;
    // flex: 1 1;
  }
  
  .advisor-add-article{
    padding: 20px 30px;
  }


.article-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--Color-border-border, #E3E3E3);
    margin-bottom: 40px;
  }
  
  .tab-container {
    display: flex;
    gap: 20px;
  }
  
  .tab {
    padding: 16px;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: var(--Color-text-text, #303030);
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .tab.active {
    font-weight: 600;
    border-bottom: 2px solid #303030;
  }
