.createArticle {
  position: absolute;
  width: 450px;
  background: white;
  z-index: 1;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 2rem;
  border-left: 1px solid #d9d9d9;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */

  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
  }

  & > .header {
    height: 5rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    & > .title {
      display: flex;
      flex-direction: column;
      gap: 2px;

      & > :nth-child(1) {
        font-size: 2.5rem;
        font-weight: 500;
      }

      & > :nth-child(2) {
        font-size: 1rem;
        display: flex;
        align-items: center;
        gap: 5px;

        & > div {
          font-weight: 410;
        }

        & > :last-child {
          font-weight: 510;
          text-decoration: underline;
        }
      }
    }

    & > .btnsContainer {
      display: flex;
      align-items: center;
      gap: 1rem;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 4rem;
        width: 4rem;
        color: white;
        font-size: 1.5rem;
        border: 0.5px solid #e7e7e7;
      }

      & > :nth-child(1) {
        background: #212224;
      }

      & > :nth-child(2) {
        font-size: 2rem;
        background: #e73625;
      }
    }
  }

  & > .createArticleForm {
    margin-top: 2rem;
    // height: calc(100% - 7rem);
    // overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;

    & > .uploadPic {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > .title {
        font-size: 1.3rem;
        font-weight: 510;
      }

      & > :nth-child(2) {
        display: flex;
        gap: 1.2rem;

        & > div {
          width: 50%;
          min-width: unset;
          flex-direction: column-reverse;
        }
      }
    }

    & > .addMultipleItems {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > .title {
        font-size: 1.3rem;
        font-weight: 510;
      }

      & > .allItems {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        & > .eachItem {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          cursor: pointer;
          border: 1px solid #d9d9d9;
          height: 150px;
          width: 150px;
          position: relative;
          border-radius: 6px;
          font-size: 4.5rem;
          color: var(--main-color);

          & > .name {
            font-size: 1.1rem;
            font-weight: 500;
            color: #212224;
          }

          & > .removeBtn {
            position: absolute;
            top: -1rem;
            right: -1rem;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            cursor: pointer;
            background: #ff5752;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  & > .submitBtn {
    padding: 1.3rem 2rem;
    background: var(--main-color);
    color: white;
    font-size: 1.3rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 6px;
    text-align: center;
    margin-top: 2rem;
  }
}
