.privacyPolicy{
    padding:10vh;
    p{
        font-size: 1.6rem;
        line-height: 1.5;
        margin-bottom: 1rem;
    }
    h1{
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
    h2{
        font-size: 2rem;
        margin-bottom: 1rem;
    }
}