.donutchart {
    width: 21rem;
    display: flex;
    align-items: start;
    padding: 1rem 2rem;
    border-radius: 1rem;
    height: 9rem;
    position: absolute;
    background: white;
    z-index: 1;
    border: 0.66px solid #30A4D2;
    top: -78px;
    left: -62px;
    box-shadow: 0 0 10px;
    cursor: pointer;

    &:hover {
        transform: scale(1.02);
    }

    .textcenter {
        margin-right: 30px;
        margin-top: 5px;
    }

    .goalreach {


        font-size: 12px;
        font-weight: 400;
        line-height: 15.64px;
        text-align: left;
        color: #7A7A7A;

    }

    .goaltext {

        font-size: 30px;
        font-weight: 700;
        line-height: 39.09px;
        text-align: left;
        color: #000000;

    }

    .chart-text {
        /*font: 16px/1.4em "Montserrat", Arial, sans-serif;*/
        fill: #000;
        -moz-transform: translateY(0.25em);
        -ms-transform: translateY(0.25em);
        -webkit-transform: translateY(0.25em);
        transform: translateY(0.25em);
    }

    .chart-number {
        font-size: 0.6em;
        line-height: 1;
        text-anchor: middle;
        -moz-transform: translateY(-0.25em);
        -ms-transform: translateY(-0.25em);
        -webkit-transform: translateY(-0.25em);
        transform: translateY(-0.25em);
    }

    .chart-label {
        font-size: 0.2em;
        text-transform: uppercase;
        text-anchor: middle;
        -moz-transform: translateY(0.7em);
        -ms-transform: translateY(0.7em);
        -webkit-transform: translateY(0.7em);
        transform: translateY(0.7em);
    }

    figure {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        margin-left: -15px;
        margin-right: -15px;
        width: 7rem !important;
    }

    @media (min-width: 768px) {
        figure {
            flex-direction: row;
        }
    }

    .figure-content,
    .figure-key {
        flex: 1;
        padding-left: 15px;
        padding-right: 15px;
        align-self: center;
    }

    .figure-content svg {
        height: 6rem;
        width: 6rem;
    }

    .figure-key {
        min-width: 100px;
    }

    .figure-key [class*="shape-"] {
        margin-right: 6px;
    }

    .figure-key-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .figure-key-list li {
        margin: 0 0 8px;
        padding: 0;
    }

    .shape-circle {
        display: inline-block;
        vertical-align: middle;
        width: 22px;
        height: 22px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
    }

    .shape-fuschia {
        background-color: #30A4D2;
    }

    .shape-lemon-lime {
        background-color: #b1c94e;
    }

    .shape-blue {
        background-color: #377bbc;
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

}

.donutchart {}

.rightsidechatside {
    width: 350px;
    border: 0.66px solid #30A4D2;
    border-radius: 12px;
    padding: 1rem;

    .text-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.planb-dashboard {


    .bottom-message {
        width: 30rem;
        display: flex;
        align-items: start;
        padding: 1rem;
        border-radius: 1rem;
        height: 8rem;
        position: absolute;
        background: white;
        z-index: 1;
        border: 0.66px solid #30A4D2;
        bottom: -60px;
        left: -115px;
        box-shadow: 0 0 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

        svg {
            margin-right: 12px;
        }

        .text {
            //styleName: Body/Content/Lar/Reg;

            font-size: 16px;
            font-weight: 400;
            line-height: 23.45px;
            text-align: left;
            color: #000000;

        }

        .date {
            //styleName: Body/Content/Link/Reg;

            font-size: 10px;
            font-weight: 400;
            line-height: 15.64px;
            text-align: left;
            color: #7A7A7A;

        }
    }

    .rightside-chartsection {
        position: relative;
        margin-top: 8.5rem;
    }

    .pageOne {
        display: flex;
        align-items: start;
        justify-content: space-between;
        max-width: 1300px;
        /* padding: 0 2rem; */
        margin: auto;
        padding-top: 14rem;
    }

    .incometext {
        //styleName: Body/Content/Med/SB;

        font-size: 16px;
        font-weight: 600;
        line-height: 20.85px;
        text-align: left;
        color: #000000;

    }

    .labeldropdown {
        //styleName: Content/small;

        font-size: 14px;
        font-weight: 400;
        line-height: 16.59px;
        text-align: left;
        color: #8F8F8F;
        width: 72.55px;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        padding: 0 5px;
        border: 1px solid #E0E0E0
    }

    .leftsidetext {
        .tableinside {
            display: flex;
            grid-gap: 1.3rem;
            gap: 6.3rem;
            margin-top: 5rem;

        }

        .cointingtable {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .value {
            text-align: center;
            font-size: 40px;
            font-weight: 700;
            line-height: 55.15px;
            text-align: left;
            color: #000000;
        }

        .title {
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 26.06px;
            text-align: left;

            color: #7A7A7A;
        }

        h1 {
            //styleName: Heading/Banner;

            font-size: 50px;
            font-weight: 700;
            line-height: 58.21px;

            text-align: left;
            color: #000;

        }

        span {
            font-size: 50px;
            font-weight: 700;
            line-height: 58.21px;

            text-align: left;
            color: #30A4D2 !important;

        }

        h2 {

            font-size: 38px;
            font-weight: 700;
            line-height: 65.15px;
            text-align: left;
            color: #666666;
            margin-top: 15px;
            margin-bottom: 17px;
        }

        p {
            //styleName: Heading/h3/med;

            font-size: 17px;
            font-weight: 500;
            line-height: 24.06px;
            text-align: left;
            color: #7A7A7A;
            width: 600px;

        }
    }
}

.borderlinemiddle {
    background: #30A4D2;
    width: 100%;
    height: 83.46px;

    margin-top: 1.5rem;
}

.pagwtwo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1300px;
    /* padding: 0 2rem; */
    margin: auto;
    padding: 115px 0;
    padding-bottom: 5rem;

    .cardss {
        margin-bottom: 5rem;
        width: 31%;

        svg {
            margin-bottom: 1.5rem;
        }

        .des {
            //styleName: Body/Content/Lar/Reg;

            font-size: 16px;
            font-weight: 400;
            line-height: 21.45px;
            text-align: left;
            color: #7A7A7A;
            margin-bottom: 0rem;


        }

        .title-text {
            margin-bottom: 1.5rem;
            font-size: 22px;
            font-weight: 600;
            line-height: 29.57px;
            text-align: left;
            color: #02364A;


        }
    }
}


.page-four {
    max-width: 1300px;
    margin: auto;

    padding-bottom: 7rem;

    .clientName {
        //styleName: Heading/h2/bold;

        font-size: 30px;
        font-weight: 700;
        line-height: 39.09px;
        text-align: center;
        color: #000;
        color: #000;
        margin-bottom: 2rem;
    }

    .cardimg {

        height: 314px;

        border-radius: 0px 50px 0px 50px;

        background: #30A4D2;
        padding: 2rem;
        opacity: 0.3;
        display: flex !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;


        img {
            margin-bottom: 1.5rem;
        }

        .titel {
            //styleName: Heading/h3/bold;

            font-size: 18px;
            font-weight: 700;
            line-height: 26.06px;
            text-align: center;
            margin-bottom: 1.5rem;
            color: #FFFFFF;

        }

        .des {
            //styleName: Body/Content/Lar/Reg;

            font-size: 16px;
            font-weight: 400;
            line-height: 23.45px;
            text-align: center;
            color: #FFFFFF;
        }
    }
}

.slick-slide {
    transform: scale(0.7);
}

.slick-center {
    transform: scale(1) !important;


    .cardimg {
        width: 339px !important;
        height: 314px !important;
        opacity: 1 !important;

    }
}

.slick-slider {
    padding: 27px 0;
}

.sec-five {
    max-width: 1300px;
    margin: auto;
 
    margin-top: 6rem;
    margin-bottom: 4rem;
    .box-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .title {
        //styleName: Heading/h2/bold;

        font-size: 30px;
        font-weight: 700;
        line-height: 39.09px;
        text-align: center;
        color: #000;
        margin-bottom: 2rem;
    }

    .box {
        width: 70%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.8rem;
        border: 2px solid #B4AFAF;
        font-size: 16px;
        font-weight: 400;
        line-height: 23.45px;
        text-align: left;
        color: #000;
        border-radius: 10px;

        &:hover {
            cursor: pointer;
            transform: scale(1.02);
        }
    }
}


.footers {
    max-width: 1300px;
    margin: auto;
    padding-top: 9rem;
    padding-bottom: 0.5rem;

    .box {
        width: 13%;

        img {
            width: 100%;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .input-box {
        width: 370px;
        height: 46px;
        position: relative;
        border-radius: 100px;
        background: #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0.5rem;

        .box-bottom-table {
            display: flex;
            align-items: self-start;
            justify-content: space-between;
        }

        .Subscribe {
            //styleName: M3/label/large;

            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: center;
            color: #FFFFFF;
            width: 112px;
            height: 33px;
            background: #30A4D2;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 100px;

            &:hover {
                transform: scale(1.03);
            }

        }

        input {

            font-size: 14px;
            font-weight: 500;
            line-height: 16.41px;
            text-align: left;
            padding-left: 1rem;
            color: #000;
            background: transparent;
            border: 0px;
            height: 100%;

            &::placeholder {
                color: #000;
            }
        }
    }

    .text-sub {

        font-size: 14px;
        font-weight: 600;
        line-height: 16.59px;
        text-align: left;
        color: #000;

    }

    .footerleftside {
        display: flex;
        align-items: center;
        gap: 4rem
    }

    .box-table {
        display: flex;
        justify-content: space-between;
        align-items: start;
    }

    .box-bottom-table {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
    }

    .getintouch {

        font-size: 18px;
        font-weight: 600;
        line-height: 21.33px;
        text-align: left;
        color: #000;
    }

    .copyrights {

        font-size: 12px;
        font-weight: 400;
        line-height: 14.22px;
        letter-spacing: 0.03em;
        text-align: center;
        color: #7A7A7A;

    }

    .bottom-copty {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.7rem;
    }

    .righstideunderline {
        display: flex;
        align-items: center;
        gap: 2.5rem;


        text-decoration: underline;

        div {
            cursor: pointer;
            font-size: 12px;
            font-weight: 600;
            line-height: 14.22px;
            letter-spacing: 0.03em;
            text-align: center;
            color: #7A7A7A;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .imgsect {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        img {
            cursor: pointer;

            &:hover {
                transform: scale(1.02);
            }
        }

    }

    
    .email {

        font-size: 16px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
        color: #666666;
        margin-top:1rem;
        margin-bottom:1rem;
    }

    .header {

        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        color: #000;
        margin-bottom: 0.3rem;

    }

    .text {
        margin-top: 0.7rem;
        font-size: 13px;
        font-weight: 400;
        line-height: 16.59px;
        text-align: left;
        color: #7A7A7A;

        &:hover {
            transform: scale(1.03);
        }
    }
}

.pagethree {
    max-width: 1300px;
    margin: auto;

    padding-bottom: 15rem;
    padding-top: 6rem;
    .threebox {
        display: flex;

    }

    .whatdo {
        //styleName: Heading/h2/bold;

        font-size: 30px;
        font-weight: 700;
        line-height: 39.09px;
        text-align: left;
        color: white;
        margin-bottom: 2rem;
    }

    .lol {
        padding: 1.5rem;
        background: #30A4D2;
        padding-bottom: 6rem;
    }

    .whatdotext {
        //styleName: Body/Content/Lar/Reg;

        font-size: 16px;
        font-weight: 400;
        line-height: 23.45px;
        text-align: left;
        color: white;


    }

    h1 {

        font-size: 36px;
        font-weight: 700;
        line-height: 39px;
        text-align: left;
        color: #000;
    }

    p {

        font-size: 16px;
        font-weight: 500;
        line-height: 26.12px;
        text-align: left;
        margin: 2rem 0;

        color: #7A7A7A;
    }

    label {
        width: 194px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px #1C18151A;
        background: #30A4D2;
        color: white;
        font-size: 14px;
    }
}


.lanbding-nav {

    height: 64px;
    background: white;

    z-index: 99;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;

    .nav-tabs-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1300px;
        margin: auto;

    }

    label {
        width: 102px;
        height: 40px;


        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        background: #30A4D2;
        border: 1px solid #2087B0;
        padding: 0 0.8rem;
        font-size: 14px;

        &:hover {
            transform: scale(1.03);
        }
    }

    .dropdown-container {
        position: relative;
    
        .dropdown-menu {
          position: absolute;
          top: 50px; 
          right: 0;
          background: white;
          border: 1px solid #E5E5E5;
          border-radius: 10px;
          width: 102px;
          height: 82px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          z-index: 1000;
        }
    
        .dropdown-menu div {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.07px;
          color: #110F0D;
          padding: 10px;
          cursor: pointer;
        }
    
        .dropdown-menu div:hover {
          background: #f0f0f0;
        }
      }
    

    .text-nav {
        //styleName: Body/Content/Med/SB;
        display: flex;
        align-items: center;
        gap: 3.5rem;



        div {
            font-size: 16px;
            font-weight: 600;
            line-height: 20.85px;
            text-align: left;
            color: #3D3D3D;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }
          

    }
}