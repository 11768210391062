.advisorSidebarLatest {
  width: 260px;
  // background: #58b135;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  left: 0;
  top: 0;
  z-index: 1000;
  border-right: 1px solid #d9d9d9;
  font-size: 1.4rem;
  gap: 15px;
  padding-top: 3rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  & > .eachSidebarItem {
    width: 100%;

    & > .eachItem {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      width: 100%;
      padding: 13px 5px;
      cursor: pointer;
      font-size: 1.5rem;
      font-weight: 410;
      border-radius: 6px;
      // transition: all linear 0.3s;

      &:hover {
        background: #58b135;
        color: white;

        svg {
          color: white;
        }
      }

      & > :nth-child(1) {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      & > svg {
        font-size: 1.7rem;
        transition: all linear 0.3s;
      }

      & > .rotateArrow {
        rotate: 180deg;
      }
    }

    & > .subMenu {
      margin-top: 1rem;

      & > div {
        padding: 15px 2rem;
        cursor: pointer;
        padding-left: 25px;
        font-size: 1.35rem;
        font-weight: 420;
        transition: all linear 0.3s;

        &:hover {
          background: #58b135;
          color: white;

          svg {
            color: white;
          }
        }
      }

      & > .selectedSubMenu {
        background: #58b13526;
        color: #58b135;
        border-radius: 6px;
      }
    }

    & > .notClickable {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
