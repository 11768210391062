.ecosystem-single-container-image {
  //   position: relative;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-evenly;
  /* padding: 20px; */
  // width: 100%;
  width: 28%;
  margin: 30px;
  min-height: 100%;
  // height: 100%;
  //   border-radius: 20px;
  // max-height: 230px;
  min-height: 200px;
  perspective: 1500px;
  min-width: 380px;
}

.ecosystem-single-container-image:hover .eco-content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}
.eco-content > :first-child > img {
  height: 80%;
  width: 80%;
}

@media screen and (max-width: 900px) {
  .ecosystem-single-container-image {
    min-height: 150px;
  }
}

@media screen and (max-width: 770px) {
  // .ecosystem-single-container-image {
  //   min-height: 50px;
  // }

  .eco-content > :first-child > img {
    width: 70%;
    height: 70%;
  }

  .eco-content > :first-child {
    padding: 3%;
  }
}

@media screen and (max-width: 610px) {
  .eco-content > :first-child > img {
    width: 60%;
    height: 60%;
  }

  .ecosystem-single-container-image {
    min-width: 280px;
    min-height: 180px;
  }
}

@media screen and (max-width: 500px) {
  .ecosystem-single-container-image {
    min-height: 150px;
    min-width: 270px;
  }
}

.eco-content {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.eco-content > :first-child {
  position: absolute;
  inset: 0;
  font-size: calc(10px + 0.4vw);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  line-height: 180.9%;
  color: #000000;
  padding: 5%;
  width: 100%;
  height: 100%;
  margin: auto;
  //   transition: all ease-in-out 1s;
  backface-visibility: hidden;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  font-weight: 800;
  color: #5f6163;
  text-align: center;
}

.eco-content > :last-child {
  transition: all ease-in-out 1s;
  padding: 10% 7%;
  height: 100%;
  color: #5f6163;
  text-align: left;
  white-space: normal;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 600;
  position: absolute;
  inset: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.eco-content > :last-child > :last-child {
  cursor: pointer;
  padding: 10px 15px;
  width: max-content;
  white-space: nowrap;
  margin-top: 5%;
  color: white;
  border-radius: 8px;
  font-size: calc(13px + 0.2vw);
  display: none;
}

.ecosystem-single-container-image:hover .eco-content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.fullwidthard {
  width: 45%;
  margin: 0;
  height: 100%;
  // min-width: 30vw;
  // min-height: 15vw;
}

@media (min-width: 1600px) {
  .ecosystem-single-container-image {
    min-height: 15vw;
    min-width: 30vw;
    max-width: 550px;
    max-height: 280px;
  }
}
