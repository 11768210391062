.clientCard {
    .client-time {
        margin-top: auto;
        margin-left: auto;
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.64px;
        color: #666666;
        padding-bottom: 12px;
    }

    img{
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
}