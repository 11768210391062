.adInivial {
  background: #e5e5e54a;
  height: calc(100vh - 74px);
  overflow: scroll;
  padding: 0 3vw;
  // padding: 0 10vw;
  padding-top: 9rem;

  &::-webkit-scrollbar {
    display: none;
  }

  .titlediv {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3.5rem;

    .pagetitle {
      color: #110f0d;
      font-family: Montserrat;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
      line-height: normal;
    }

    .para {
      color: #110f0d;
      text-align: right;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 5px;
      margin-left: 4px;
    }
  }

  .linedatalist {
    width: 100%;
    height: 2px;
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
    background: #e5e5e5;
  }

  .topInputSearchsection {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      border-radius: 10px;
      border: 0.5px solid #e5e5e5;
      background: #fff;
      width: 499px;
      height: 56px;
      flex-shrink: 0;
      color: #65686d;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      padding-left: 12px;

      &::placeholder {
        opacity: 0.25;
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 0px;
        border-radius: 10px;
        border: 0.5px solid #e5e5e5;
      }
      &:focus {
        border-radius: 10px;
        border: 0.5px solid #e5e5e5;
      }
    }

    .dropadinviualselect {
      display: flex;
      align-items: center;
    }

    select {
      width: 181px;
      height: 37px;
      flex-shrink: 0;
      border-radius: 5px;
      border: 1px solid #e5e5e5;
      background: #fff;
      color: #110f0d;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-right: 5px;
      padding-left: 5px;

      &::placeholder {
      }
    }

    option {
      // color: black;
      &:first-of-type {
        opacity: 0.25;
      }
    }

    .nameListtitle {
      color: #110f0d;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      margin-right: 10px;
      line-height: normal;
    }
  }
}

.advesorcard {
  // padding: 0 4rem 0 3rem;
  // height: calc(100vh - 0rem);
  // overflow: scroll;
}

.advisorItemlist {
  display: flex;
  flex-wrap: wrap;
  gap: 3.5%;
  margin-top: 3rem;

  .ad-card {
    border-radius: 15px;
    border: 1px solid #e5e5e5;
    background: rgba(255, 255, 255, 0.1);
    width: 31%;
    background: white;
    padding: 2rem;
    margin-bottom: 3rem;
    cursor: pointer;
    transition: all ease 0.3s;
    transition: all ease 0.3s;

    &:hover {
      transform: scale(1.03);
    }

    .seletbtn {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #58b135;
      border-radius: 35px;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        transform: scale(1.03);
      }
    }

    .profilebtn {
      color: #110f0d;
      border-radius: 35px;
      border: 1px solid #e5e5e5;
      background: #fff;
      height: 41px;
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover {
        transform: scale(1.03);
      }
    }

    .item-inside {
      .value {
        color: #110f0d;
        font-family: Montserrat;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .valueName {
        color: #110f0d;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.3rem;
      }
    }

    .insidecart {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
    }

    .level {
      color: #110f0d;
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .name {
      color: #110f0d;
      text-align: center;
      font-family: Montserrat;
      font-size: 17px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }

    img {
      border-radius: 98px;
      // border: 1px solid #58B135;
      margin: auto;
      display: flex;
      border: 1px solid #58b135;
      width: 98px;
      height: 98px;
      flex-shrink: 0;
    }
  }
}

.pagemainflex {
  // display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;

  .rightsidedatashow {
    width: calc(100vw - 77px);
  }
}
