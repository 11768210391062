.advisor-chat-pagemainflex {
  // display: flex;
  align-items: start;
  height: 100vh;
  overflow: hidden;
}

.advisor-chat-advisor-rightsidedatashow {
  width: calc(100vw - 77px);
  overflow-y: auto;
  padding-bottom: 100px;
  height: 100%;
}

.searchProfileContainer {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 20px 0 30px;
}

.searchBoxContainer {
  display: flex;
  align-items: center;
  border-radius: 8px;
  flex-grow: 1;
  padding: 10px 10px;
  background-color: #fff;
  box-shadow: 0px 2px 2px 0px #a0a0a040;
  border: 1px solid #d9d9d9;
  width: 520px;
  max-width: 520px;
  margin-left: 10px;
}

.searchIcon {
  color: #7a7a7a;
  width: 20px;
  height: 20px;
}

.searchInput {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: left;
  color: #7a7a7a;
  border: none;
  outline: none;
  margin-left: 10px;
  flex-grow: 1;
}

.searchInput::placeholder {
  color: #7a7a7a;
}

.profileTextContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000000;
  padding: 5px 10px;
  border-radius: var(--p-border-radius-button);
}

.profileName {
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26.06px;
  text-align: left;
  color: #000000;
  margin-right: 5px;
}

.viewProfile {
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 23.45px;
  text-align: left;
  color: #7a7a7a;
  text-decoration: none;
}

.chatContainer {
  display: flex;
  gap: 40px;
  padding: 0 20px;
  border-radius: 8px;
  height: calc(100% - 108px);
}

.chatBox {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 1px 3px 0px #0000004d, 0px 1px 8px 2px #00000026;
  border-radius: 20px;
  padding: 10px;
  background: #fff;
  height: calc(100% - 60px);

  .chatContent {
    flex: 1;
    padding: 5px;
    border-radius: 8px;
    background: #fff;
    overflow-y: auto;

    .message {
      margin-bottom: 20px;
      background: #eff0f3;
      border-radius: 8px;
      padding: 10px;

      .messageText {
        padding: 10px;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.85px;
        color: #525252;
      }

      .messageDate {
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.64px;
        color: #a3a3a3;
        margin-top: 5px;
        text-align: right;
      }
    }
  }

  .chatInput {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0px 1px 0px 0px #1a1a1a12, 0px 1px 0px 0px #cccccc80 inset,
      0px -1px 0px 0px #0000002b inset, -1px 0px 0px 0px #00000021 inset,
      1px 0px 0px 0px #00000021 inset;
    background: #fff;
    border: 1px solid #ccc;

    .quill-toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 10px;
      background: #eff0f3;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .toolbar-buttons-left {
      display: flex;
      gap: 10px;
    }

    .sendButton {
      background: none;
      border: none;
      cursor: pointer;
      margin-left: auto;
      width: 38px;
      height: auto;

      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .editor {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;

      .ql-container {
        flex: 1;
        border: none;
      }

      .ql-editor {
        flex: 1;
        font-family: "Hanken Grotesk", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.85px;
        color: #525252;

        &::placeholder {
          font-family: Poppins;
          font-size: 15px;
          font-weight: 400;
          line-height: 22.5px;
          text-align: left;
        }
      }
    }
  }
}

.reportsBox {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px #0000004d, 0px 1px 8px 2px #00000026;
  background: #fff;
  height: 70%;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .reportsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .reportsTitle {
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 26.06px;
      color: #000000;
    }

    .filterIcon {
      width: 35px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #58b135;
      border-radius: 8px;
      cursor: pointer;
      box-shadow: 1px 4px 4px 0px #00000040;
    }
  }

  .reportSection {
    margin-bottom: 25px;

    .reportCategory {
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 18.24px;
      color: #7a7a7a;
      margin-bottom: 10px;
    }

    .reportItem {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:hover {
        transform: scale(1.02);
      }

      .reportImage {
        width: 44px;
        height: 40px;
        object-fit: cover;
        border-radius: 8px;
        margin-right: 10px;
      }

      .reportDetails {
        .reportTitle {
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 18.24px;
          color: #000000;
        }

        .reportId {
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 15.64px;
          color: #8f8f8f;
        }
      }
    }
  }
}
