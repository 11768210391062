.third-section {
    width: 100%;
    height: 50vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.third-section>div {
    padding: 5vh var(--common-padding);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 5rem;
}

.third-section-content>:first-child {
    width: 35%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.third-section-content>:first-child img {
    max-width: 100%;
    max-height: 100%;
}

.third-section-content>:last-child {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    justify-content: center;
}

.third-section-content-last>:first-child {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.5;
    color: black;
    /* width: 80%; */
}

.third-section-content-last>:nth-child(2) {
    font-size: 2rem;
    font-weight: 400;
    color: #000000;
    width: 80%;
}

.third-section-content-last>:nth-child(3) {
    width: max-content;
    font-size: 1.5rem;
    font-weight: 500;
    color: white;
    padding: 1.5rem 2rem;
    background: #58B135;
    border: 0.5px solid #58B135;
    border-radius: 100px;
    cursor: pointer;
}

@media screen and (max-width:600px) {
    .third-section-content>:first-child {
        display: none;
    }

    .third-section-content>:last-child {
        width: 100%;
    }

    .third-section {
        height: 40vh;
    }

    .third-section>div {
        padding: 0 var(--common-padding);
    }
}