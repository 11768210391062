.loginPage {
  height: 100vh;
  background: var(--main-color-light);
  display: flex;
  align-items: center;
  justify-content: center;

  .endside {
    // border: 1px solid var(--White, #ffffff);

    background: linear-gradient(
      98.74deg,
      rgba(255, 255, 255, 0.3) 14.38%,
      rgba(255, 255, 255, 0.15) 84.13%
    );
    border-radius: 0 6px 6px 0;

    width: 4rem;
    height: 29rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .left {
    // border: 1px solid var(--White, #ffffff);
    border-radius: 6px 0 0 6px;
    background: linear-gradient(
      98.74deg,
      rgba(255, 255, 255, 0.3) 14.38%,
      rgba(255, 255, 255, 0.15) 84.13%
    );
    padding-left: 6rem;
    width: 51rem;
    height: 29rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .signup {
      width: 139px;
      height: 45px;
      margin-top: 2.5rem;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      line-height: 26.06px;
      text-align: left;
      background: var(--main-color);
      //   border: 3px solid var(--White, #ffffff);
      color: white;
      cursor: pointer;

      &:hover {
        transform: scale(1.03);
      }
    }

    .dontacount {
      //styleName: Heading/Bannersub;

      //styleName: Heading/Bannersub;
      font-size: 47px;
      font-weight: 600;
      line-height: 59.15px;
      text-align: left;
      width: 350px;
      color: var(--main-color);
    }
  }

  .logintext {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 500;
    line-height: 36.57px;
    text-align: left;
    color: #666666;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      height: 6rem;
      margin: 0 auto;
    }
  }

  .right {
    width: 500px;
    background: #ffffff;
    padding: 46px 60px;
    border-radius: 6px;
    position: relative;

    .socailasection {
      display: flex;
      align-items: center;

      gap: 1rem;
      margin-top: 7px;

      svg {
        &:hover {
          transform: scale(1.03);
        }
      }
    }

    .socialmedia {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 371px;
      margin-top: 2.5rem;

      .texttoconti {
        color: #8f8f8f;

        font-size: 12px;
        font-weight: 400;
        line-height: 15.64px;
        text-align: center;
      }
    }

    .forgotsection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 3rem;
      width: 371px;

      .text {
        //styleName: Body/Content/Lar/SB;

        font-size: 16px;
        font-weight: 600;
        line-height: 23.45px;
        text-align: left;
        color: #a3a3a3;
      }

      label {
        width: 121px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        font-size: 16px;
        font-weight: 500;
        line-height: 23.45px;

        color: white;
        background: var(--main-color);

        border: 1px solid var(--main-color);
        cursor: pointer;

        &:hover {
          transform: scale(1.03);
        }
      }

      .notclickable {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .input-section {
      width: 371px;
      position: relative;
      height: 43px;
      margin-top: 2.5rem;

      svg {
        position: absolute;
        top: 12.5px;
        right: 0.5rem;
        height: 15px;
      }

      input {
        color: #666666;
        height: 43px;
        font-size: 16px;
        font-weight: 500;
        border: 0px solid;
        width: 100%;
        line-height: 23.45px;
        text-align: left;
        padding: 0 0.5rem;
        border-bottom: 1px solid #666666;
        background: transparent;

        &:focus-visible {
          outline-offset: 0px;
        }

        &:focus-visible {
          outline: -webkit-focus-ring-color auto 0px !important;
        }
      }
    }

    .signUpContainer {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 3rem;

      .flexContainer {
        display: flex;
        align-items: center;
        gap: 1rem;

        & > input {
          border: 1px solid #e5e5e5;
          border-radius: 6px;
          outline: none;
          padding: 1.35rem 1.5rem;
          width: 100%;
          height: 100%;

          &:focus {
            border-color: var(--main-color);
          }
        }
      }

      .passwordRequirments {
        margin-top: 1rem;

        & > .title {
          font-size: 1.1rem;
          margin-bottom: 1.2rem;
          font-weight: 450;
        }

        & > .allRequirements {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          border-radius: 6px;
          background: #fffdf2;
          padding: 1.5rem;

          & > .eachRequirement {
            display: flex;
            align-items: center;
            gap: 15px;
            font-size: 1.1rem;
            font-weight: 450;

            & > svg {
              font-size: 1.4rem;
              color: black;
            }

            & > .requirementMet {
              color: var(--main-color);
            }
          }
        }
      }
    }
  }

  .loadingDiv {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    & > img {
      height: 7rem;
      animation: heartbeat 1s infinite;
    }

    @keyframes heartbeat {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.2);
      }

      100% {
        transform: scale(1);
      }
    }
  }
}
