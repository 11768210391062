.landing-center-para {
    padding: 5vh var(--common-padding);
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.5;
    color: #0A0B0B;
    text-align: center;
}

.landing-center-para2 {
    background: #58B135;
    padding: 5rem var(--common-padding);
    /* padding: 5vh calc(2 * var(--common-padding)); */
}

.landing-center-para3 {
    background: #58B135;
    padding: 3rem var(--common-padding);
    /* padding: 5vh calc(2 * var(--common-padding)); */
}

.landing-center-para2>div {
    background: white;
    white-space: nowrap;
    text-align: center;
    color: #58B135;
    font-size: 1.5rem;
    width: max-content;
    margin: auto;
    border-radius: 100px;
    padding: 1rem 2rem;
    margin-top: 5rem;
    font-weight: 600;
    cursor: pointer;
}

.landing-center-para3>div {
    white-space: nowrap;
    background: white;
    text-align: center;
    color: #58B135;
    font-size: 1.5rem;
    width: max-content;
    margin: auto;
    border-radius: 100px;
    padding: 1rem 2rem;
    margin-top: 2rem;
    font-weight: 600;
    cursor: pointer;
}

.ninth-section {
    width: 80%;
    min-height: 230px;
    min-height: 320px;
    display: flex;
    /* grid-template-columns: repeat(2, 1fr); */
    gap: 30px;
    margin: auto;
    flex-wrap: wrap;
    padding: 5vh var(--common-padding);
    margin-bottom: 12vh;
    justify-content: center;
}

.main-sidebar {
    width: 85%;
    max-width: 386px;
    /* height: 100%; */
    overflow: scroll;
    /* padding-top: 20%; */
    position: fixed;
    /* top: 0;
    bottom: 0;
    right: 0; */
    left: 0;
    background: white;
    z-index: 2;
    transition: all ease-in 0.5s;
    /* display: flex; */
    flex-direction: column;
    gap: 10px;
    top: calc(1vh + 57px);
    display: flex;
    justify-content: space-evenly;
    bottom: 0;
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: #0A0B0B;
    opacity: .5;
    z-index: 1;
    transition: all ease-in 0.5s;
}

.eachsidebarcard {
    width: 100%;
    height: 12%;
    padding: 10px 10%;
    display: flex;
    gap: 5%;
}

.eachsidebarcard>:first-child {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.eachsidebarcard>:first-child img {
    object-fit: contain;
    width: 80%;
    height: 80%;
}

.eachsidebarcard>:last-child {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.eachsidebarcard>:last-child>:first-child {
    font-size: 3rem;
    font-weight: 600;
}

.eachsidebarcard>:last-child>:last-child {
    font-size: 2rem;
    font-weight: 600;
}