.editing-page {
  padding: 0 20px;
  height: calc(100% - 34px - 3rem);
  overflow: scroll;
  background: #fafafa;

  &::-webkit-scrollbar {
    display: none;
  }

  .Innerinnputsection {
    display: flex;
    align-items: start;

    .subdivision {
      width: 48%;

      & > div {
        max-height: 100%;
      }
    }
  }

  .textheader {
    color: #110f0d;

    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
  }

  .searchBoxContainer {
    display: flex;
    align-items: center;
    border-radius: 8px;
    flex-grow: 1;
    padding: 10px 10px;
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3019607843),
      0px 1px 8px 2px rgba(0, 0, 0, 0.1490196078);
    width: 520px;
    max-width: 520px;
  }

  .searchIcon {
    color: #7a7a7a;
    width: 20px;
    height: 20px;
  }

  .searchInput {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    text-align: left;
    color: #7a7a7a;
    border: none;
    outline: none;
    margin-left: 10px;
    flex-grow: 1;
  }

  .profileIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 0.5px solid #ddd;
  }

  .searchProfileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 0 20px;
  }

  .textSectionTop {
    width: 25vw;
  }

  .editingSectionTop {
    display: flex;
    align-items: start;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 4rem 0;
    padding-bottom: 2rem;
  }

  .notClickable {
    pointer-events: none;
  }

  .Innerinnputsection {
    width: 100%;
    margin-bottom: 2rem;
    gap: 4%;
  }

  .labelsubmit {
    display: flex;
    align-items: self-start;
    justify-content: end;
    padding-top: 20px;

    .submitlabel {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      width: 201.4px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      cursor: pointer;
      background: #110f0d;

      &:hover {
        transform: scale(1.03);
      }
    }
  }
}

.profile-rightsidedatashow {
  width: calc(100vw - 77px);
  // overflow-y: auto;
  padding-bottom: 20px;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.advisor-profile-editing {
  // display: flex;
  // align-items: start;
  height: 100vh;
  width: 100%;

  .flexContainers {
    display: flex;
    height: calc(100% - 65px);
  }

  .tabs {
    display: flex;
    align-items: start;
    gap: 2rem;
    padding: 0 20px;
    margin-top: 3rem;
  }

  .line {
    background: rgb(88, 177, 53);
    height: 2px;
    width: 100%;
  }

  .name {
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #110f0d;

    &:hover {
      transform: scale(1.03);
    }
  }
}

.eddintright {
  width: calc(100% - 25vw);
}

.profileIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.profileIconsContainer {
  display: flex;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  margin-left: auto;
  border-left: 1px solid #d9d9d9;
  padding-left: 2rem;
  height: 100%;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  .profileContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    & > :nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 3px;

      & > :nth-child(1) {
        font-size: 1.5rem;
        font-weight: 420;
      }

      & > :nth-child(2) {
        font-size: 1.3rem;
        font-weight: 400;
      }
    }
  }

  & > svg {
    font-size: 1.5rem;
  }

  & > .profileDropdown {
    position: absolute;
    top: 7rem;
    z-index: 1;
    background: white;
    box-shadow: 0px 1px 3px 0px #0000004d;
    border-radius: 6px;
    width: 95%;
    padding: 1rem 0;

    & > div {
      padding: 1rem 1.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 12px;
      color: #110f0d;
      font-size: 1.5rem;
      font-weight: 410;

      & > svg {
        color: var(--main-color);
        font-size: 2.2rem;
      }
    }
  }
}

.color-picker-container {
  width: 100%; /* Adjust the width as needed */
}

.color-picker-container .sketch-picker {
  box-shadow: none; /* Remove the default box shadow if desired */
}

.color-picker-container .sketch-picker > div:nth-child(1) {
  display: none; /* Hide the color presets if not needed */
}
