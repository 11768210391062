.marketplaceMain {
  height: 100%;
  width: calc(100vw - 77px);

  & > .allTabs {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    padding: 1rem;
    border: 1px solid #d9d9d9;

    & > div {
      padding: 0.8rem 2rem;
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.1rem;
      font-weight: 400;
      transition: all linear 0.3s;

      &:hover {
        border-color: #58b135;
        color: #58b135;
      }
    }

    & > .selectedTab {
      border-color: #58b135;
      color: #58b135;
      background: #58b13526;
      font-weight: 410;
    }
  }
}

.selectDiv {
  padding: 2rem 1rem;
  font-size: 1.4rem;

  .title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 2rem 0;
  }

  .allSelects {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > div {
      background: white;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      border-radius: 7px;
      border: 1px solid #d9d9d9;
      transition: all linear 0.3s;

      & > :nth-child(1) {
        display: flex;
        display: flex;
        gap: 1rem;
        align-items: center;
        border-radius: 7px;
        padding: 1.5rem 1rem;

        & > .profile {
          width: 200px;
          display: flex;
          align-items: center;
          gap: 1.2rem;

          & > img {
            height: 7rem;
            width: 7rem;
            border-radius: 50%;
          }
          & > .title {
            color: #000000;
            font-weight: 500;
          }
        }

        & > .desc {
          width: calc(100% - 230px);
          line-height: 1.5;
        }
      }

      &:hover {
        border-color: #58b135;
      }
    }

    .addContainer {
      background: #f5f5f5;
      padding: 0.8rem 1rem;
      border-radius: 0 0 7px 7px;
      width: 100%;

      & > .addBtn {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 0.6rem 2.5rem;
        border-radius: 8px;
        background: white;
        cursor: pointer;
        width: max-content;

        &:hover {
          background: var(--main-color);
          color: white;
        }
      }
    }
  }
}
