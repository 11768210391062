.faq-section {
    width: 100%;
    height: calc(97vh - 86px);
    max-width: calc(5vw + var(--max-container-width));
    margin: auto;
    margin-top: calc(2vh + 86px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 var(--common-padding);
}

.faq-heading {
    color: #58B135;
    font-size: calc(18px + 2.1vw);
    font-weight: 600;
    height: 10%;
    text-align: center;
}

.faq-container {
    width: 100%;
    height: 85%;
}

.faq-container>div {
    border-bottom: 1px solid #58B135;
    color: #58B135;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: calc(10px + 0.9vw);
    line-height: 30px;
    padding: 10px 0;
}

.faq-container>div>:first-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: calc(13px + 1vw);
    font-weight: 600;
    padding: 3% 0;

}

.faq-container svg {
    cursor: pointer;
}

.faq-container>div>:last-child {
    line-height: 1.5;
}