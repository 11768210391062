.nav-menu-container {
    width: 100vw;
}

.menu-flex {
    // position: relative;
}

.boxcompany {
    transform: translate(-33.5rem, -1px);
}

.airborderInfo {
    width: 2px;
    background-color: #e5e5e5;
    height: 2rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.air {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #110F0D;
    ;

    img {
        margin-left: 10px;
    }
}

.big-box1 {
    padding: 35px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    position: fixed;
    top: 95px;
    right: calc(8vw + 400px);
    z-index: 17;
}

.big-box2 {
    padding: 35px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    position: fixed;
    top: 95px;
    right: calc(8vw + 300px);
    z-index: 17;
}

.thebox {
    transform: translate(0rem, 0px);
}

.theproduct {
    transform: translate(-16rem, -0px);
}

.theAffliate {
    transform: translate(-26rem, -0px);
}

.Thecommunicty {
    transform: translate(-26rem, -0px);
}

.boxcompany {
    transform: translate(-33.5rem, -0px);
}

.big-box3 {
    padding: 35px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    position: absolute;

    top: 95px;
    z-index: 17;
    display: flex;
    justify-content: space-between;
    padding: 35px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;

    position: absolute;
    /* right: 9vw; */
    left: 0;
    top: 79px;
    z-index: 17;
    width: max-content;

    z-index: 17;
    width: 100%;
    max-width: 1498px;
    left: 50%;
    transform: translate(-50%, 0);
    justify-content: flex-start;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px 0px 25px 25px;
    border-top: 0px solid;
}

.menus-left {
    width: fit-content;
    padding-top: 35px;

    svg {
        transform: scale(1.03);
    }

    padding-bottom: 35px;
}

.menus-right {
    width: fit-content;
    margin-left: 40px;
    border-left: 1px solid #e7e7e7;
    padding-left: 40px;
    padding-right: 40px;
    border-right: 1px solid #e7e7e7;
    padding-bottom: 35px;
    padding-top: 35px;
}

.big-box4 {
    padding: 35px;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    position: fixed;
    top: 95px;
    right: calc(4vw);
    z-index: 17;
}

.menu-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #182542;
    // 
}

.menu-desc {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #182542;
    // white-space: nowrap;
}

.each-menu {
    cursor: pointer;
    margin-top: 10px;
    padding: 20px;
    display: flex;

    &:hover {
        background-color: #e7e7e7;
        border-radius: 15px;
    }
}

.menus {
    width: fit-content;
    cursor: pointer;
    margin-top: 10px;
    padding: 20px;
    padding-left: 0;
    display: flex;

    &:hover {
        // background-color: #e7e7e7;
        // border-radius: 15px;
        transform: scale(1.1);
    }
}

.menu-img-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
}

.nn-maincustom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    max-width: 1250px;
    margin: auto;
    width: 100%;

}

.slectedbottom {
    height: 36px;
    border-radius: 0px 0px 15px 15px;

    background: #58B135;

    .slectedbottombelow {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15.5rem;
        height: 36px;
        max-width: 1250px;
        margin: auto;

        div {
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }
        }
    }


}

.nn-main {
    position: fixed;
    width: 100%;
    /* margin-left: 9vh; */
    /* margin-right: 9px; */
    max-width: 1400px;

    top: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 2px 0px;
    // padding: 0px 8vw;

    justify-content: space-between;
    align-items: center;
    // padding-left: 30px;
    // padding-right: 30px;
    z-index: 999;
    background-color: white;
    border: 0.5px solid #e5e5e5;
    // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 15px;


}

.nn-img {
    height: 43.9px;
}

.nn-img1 {
    position: absolute;
    left: 2%;

    &:hover {
        transform: scale(1.1);
        transition: all ease 0.6s;
    }
}

.nn-left {
    cursor: pointer;
    height: 80px;
    display: flex;
    align-items: center;

    svg {
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }
}

.nn-right {
    display: flex;
    align-items: center;
    height: 80px;
}

.menushow {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 999;
    position: fixed;
    background: transparent;
    height: 80px;
}

.nn-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 80px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #110F0D;
    ;
    margin-left: 26px;
    position: relative;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        transition: all ease 0.3s;
    }
}

.boderlenght {
    background: #58B135;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 24px;
}

.nn-btn-login {

    display: flex;
    align-items: center;
    justify-content: center;
    color: #110F0D;
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 35px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    width: 125px;
    height: 35px;
    flex-shrink: 0;
    cursor: pointer;

    &:hover {
        transform: scale(1.01);
    }
}

.end-nav {
    display: flex;
    align-items: center;
}

.nn-btn {
    margin-left: 15px;
    width: 125px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 35px;
    border: 0.5px solid #58B135;
    background: #58B135;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;

    &:hover {
        transform: scale(1.01);
    }
}

// .nn-btn::after {
//   background: white;
//   content: "";
//   height: 155px;
//   left: -75px;
//   opacity: 1;
//   position: absolute;
//   top: -50px;
//   -webkit-transform: rotate(35deg);
//   transform: rotate(35deg);
//   transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
//   width: 50px;
//   z-index: -10;
// }

// .nn-btn:hover::after {
//   left: 120%;
//   transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
//   z-index: 50;
// }

//   .nn-box-main{
//     height: 200px;
//     width: 300px;
//     border-radius: 20px;
//     background-color: black;
//   }

.socialmedia {
    display: flex;
    margin-top: 1.7rem;
    align-items: center;

    .box {
        width: 87.73px;
        height: 87.73px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        border-radius: 15px;

        &:hover {
            transform: scale(1.1);
        }
    }
}

@media only screen and (max-width: 1569.9px) {
    .nn-img1 {
        position: inherit;
        left: 2%;
        margin-right: 11px;
    }
}

@media only screen and (max-width: 1580px) {
    // .big-box3 {

    //     left: -40vh;

    // }

    .nn-left {
        cursor: pointer;
        height: 80px;
        display: flex;
        align-items: center;
        // padding-left: 3%;
    }
}

@media only screen and (max-width: 1000px) {
    .menushow {
        display: none;
    }
}

@media only screen and (max-width: 1700px) {
    // .nn-main {
    //   width: 94%;
    // }
}