.advisor-profile-add-existing {
  padding: 20px 40px;

  h5 {
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.45px;
    text-align: left;
    color: #525252;
    padding-bottom: 8px;
  }

  .checkbox-section {
    padding-bottom: 40px;

    ol {
      display: flex;
      flex-wrap: wrap;
      max-height: 300px;
      overflow-y: auto;
      padding-left: 0;
      list-style: decimal inside;

      li {
        flex: 0 0 50%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-right: 10px;

        label {
          display: flex;
          align-items: center;
          font-family: 'Hanken Grotesk', sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 26.06px;
          color: #3D3D3D;

          input[type="checkbox"] {
            display: none;
          }

          input[type="checkbox"] + span {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
          }

          input[type="checkbox"] + span:before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            border: 2px solid #000000;
            border-radius: 4px;
            background-color: #fff;
          }

          input[type="checkbox"]:checked + span:before {
            background-color: #58B135;
          }

          input[type="checkbox"]:checked + span:after {
            content: '';
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            border-radius: 2px;
          }

          span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .service-details {
    border: 1px solid #B8B8B8;
    padding: 20px;
    box-shadow: 1px 1px 4px 0px #00000040;
    border-radius: 10px;

    .service-header {
      font-family: 'Hanken Grotesk', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 23.45px;
      color: var(--Brown, #1C1815);
      border-bottom: 1px solid #B8B8B8;
      padding-bottom: 15px;
    }

    .service-content {
      display: flex;
      gap: 20px;
      align-items: flex-start;
      padding: 25px 0px;

      .service-description {
        width: 75%;

        p {
          font-family: 'Hanken Grotesk', sans-serif;
          font-size: 20px;
          font-weight: 400;
          line-height: 26.06px;
          color: #3D3D3D;
          flex: 1;
        }
      }

      .service-image {
        width: 25%;
        height: 177px;

       > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }

        .image-upload {
          background: #F5F5F5;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;

          img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }

          p {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.1px;
            text-align: center;
            color: #000000;
            padding-top: 20px;

            span{
              text-decoration: underline;
            }
          }

          input[type="file"] {
            display: none;
          }

          .file-input-label {
            cursor: pointer;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .product-label {
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 23.45px;
        text-align: left;
        color: #525252;
    }

    .delete-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        object-fit: contain;
        margin-left: auto;
    }
}

}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  // padding: 0 40px;
  .cancel-button, .save-button {
    padding: 10px 20px;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 16px;
    cursor: pointer;
  }

  .cancel-button {
    // background-color: #f0f0f0;
    color: #1d5b05;
    border: 1px solid #79747E;
  }

  .save-button {
    background-color: #58B135;
    color: #fff;
  }
}

.add-one-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  .add-one-button {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid #1d5b05;
      font-family: 'Hanken Grotesk', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 20.85px;
      text-align: left;
      color: #666666;
      background: white;
      cursor: pointer;
  }
}
