.advisor-chat-pagemainflex {
  // display: flex;
  align-items: start;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.advisor-chat-advisor-rightsidedatashow {
  width: calc(100vw - 77px);
  overflow-y: auto;
  padding: 20px 30px;
  // flex: 1 1;
}

.social-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 30px;
}

.tab-container {
  display: flex;
  gap: 20px;
  overflow: hidden;
}

.tab {
  padding: 16px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #303030;
  background: none;
  border: none;
  cursor: pointer;

  &.active {
    font-weight: 600;
    border-bottom: 2px solid #303030;
  }
}

.filter-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.filter-icon {
  width: 18px;
  height: 18px;
  object-fit: cover;
}

.filter-text {
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.33px;
  color: #3d3d3d;
}

.applied-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-tab {
  background-color: white;
  border-radius: 8px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  border: 1px solid #666666;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: #3d3d3d;

  .remove-filter {
    margin-left: 5px;
    cursor: pointer;
  }
}

.month-selector {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.month-selector select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 20px;
  border: none;
  padding: 10px 35px 10px 0px;
}

.month-selector::after {
  content: "";
  position: absolute;
  right: 15px;
  top: 45%;
  width: 6px;
  height: 6px;
  border-left: 3px solid black;
  border-bottom: 3px solid black;
  transform: rotate(-45deg) translateY(-50%);
}

.social-content-main {
  .week-section {
    margin-bottom: 25px;
  }

  .week-title {
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 23.45px;
    color: #525252;
    margin-bottom: 15px;
  }

  .slick-slider {
    padding: 0 !important;

    .slick-list {
      margin-left: 0;
    }

    .slick-track {
      margin-left: 0;
      display: flex;
    }

    .slick-slide {
      margin: 0;
      padding: 0;
      width: auto !important;
      height: auto;
      transform: scale(0.9);
      > div {
        height: 100%;
      }
    }
  }

  .post-card {
    background: #ecfaff;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .post-image {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }

  .post-content {
    padding: 12px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .post-title {
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.24px;
    color: #000000;
    margin-bottom: 5px;
  }

  .post-date {
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 13.03px;
    color: #1d5b05;
  }
}

.create-new-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 14px 18px;
  background-color: #58b135;
  color: #fff;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 14px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 4px 0px #00000033;
  // margin-left: auto;
  // margin-right: 30px;
  position: fixed;
  bottom: 2rem;
  right: 2rem;

  &:hover {
    background-color: #2589b0;
  }
}
