.articleWrapper {
  min-height: 50vh;
  padding: 0px 100px;
  display: grid;
  // grid-template-columns: 2fr 1fr;
  gap: 40px;
  background-color: var(--highlight-color);
  position: relative;

  .stickyParent {
    // position: absolute;
    top: 5vh;

    & > .stickyContainer {
      // position: sticky;
      top: 300px;
    }
  }

  .articleSection {
    background-color: var(--theme-inside);
    margin: 5vh 0px;
    border-radius: 20px;
    padding: 40px 48px;
    // height: 100vh;
    // overflow: scroll;

    & > * {
      color: var(--font-color) !important;
    }

    .articleTitle {
      font-size: 30px;
      font-weight: 700;
    }
    .articleSubTitle {
      padding: 45px 0px;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
    }
    .articleMedia {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
  }
  .articleContent {
    font-weight: 400;
    font-size: 30px !important;
    line-height: 35px;
    padding: 45px 0px;
    width: 100%;
    font-family: "Montserrat" !important;
    font-size: 16px;
    line-height: 27px !important;

    * {
      line-height: 27px !important;
      margin-bottom: 12pt !important;
      max-width: 100% !important;
      color: var(--font-color) !important;
      font-size: 16px !important;
    }

    a {
      line-height: 27px !important;
      margin-bottom: 12pt !important;
      max-width: 100% !important;
      color: var(--font-color) !important;
      font-size: 16px !important;
      text-decoration: underline !important;
    }

    ul {
      width: 100% !important;
      color: var(--font-color) !important;
    }

    li {
      width: 100% !important;
      color: var(--font-color) !important;
    }

    ul > li p {
      width: 100% !important;
      line-height: 170% !important;
      color: var(--font-color) !important;
    }

    img {
      width: 100% !important;
      max-width: 100% !important;
      object-fit: cover;
      height: 100%;
      color: var(--font-color) !important;
    }
    span {
      width: 100% !important;
      max-width: 100% !important;
      height: 100%;
      color: var(--font-color) !important;
      font-family: "Montserrat" !important;
    }
  }

  .articleContent > p {
    font-size: 22px !important;
    font-family: "Montserrat" !important;
    line-height: 165% !important;
    margin-bottom: 12pt !important;
  }

  .articleContent > span {
    width: 100% !important;
    max-width: 100% !important;
    height: 100%;
    color: var(--font-color) !important;
    line-height: 27px !important;
    font-size: 15px !important;
    font-family: "Montserrat" !important;
  }
  .articleContent > h2 {
    font-size: 30px !important;
  }

  .otherSection {
    background-color: var(--theme-main);
    margin: 5vh 0px;
    border-radius: 20px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    gap: 20px;
    // align-items: center;
    // position: sticky;
    // top: calc(155px + 5vh);
    // bottom: 0;
    // height: calc(95vh - 155px);
    height: max-content;
    // height: 100vh;
    // overflow: scroll;

    .cardStyle {
      border-radius: 15px;
      padding: 10px;
      border: 1px solid var(--bordercolor-main);
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .cardLable {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        padding: 10px 0px;
      }
      .cardData {
        font-weight: 800;
        font-size: 25px;
        line-height: 35px;
        padding: 10px 0px;
      }
    }
  }
}

.pillWrapper {
  // border-top: 1px solid var(--bordercolor-main);
  // margin-top: 39px;
  padding-top: 39px;

  & > :nth-child(1) {
    font-size: 20px;
    padding: 10px;
    background: var(--highlight-color);
    font-weight: 500;
    border-radius: 5px;
  }

  & > :nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 39px;
  }
  // margin-right: ;
}

.actionBtn {
  width: max-content;
  background: var(--background-color);
  border-radius: 35px;
  padding: 1rem 2rem;
  cursor: pointer;
  // position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  z-index: 2;
  transition: all ease-in 0.2s;

  & > div {
    white-space: nowrap;
    color: white !important;
  }

  & > :nth-child(2) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in 0.2s;

    &:hover {
      scale: 1.05;
    }
  }

  &:hover {
    scale: 1.05;
  }
}
.searchPill {
  padding: 12px 43px;
  font-size: 16px;
  margin-bottom: 22px;
  margin-right: 27px;
  font-weight: 500;
  // background: var(--highlight-color);
  border: 0.5px solid var(--bordercolor-main);
  border-radius: 35px;
  cursor: pointer;
  // &:hover {
  //   cursor: pointer;
  //   background: var(--background-color);
  //   color: var(--font-color-opposite);
  //   border: 1px solid #4b2a91;
  //   font-weight: 700;
  //   transition: all 0.3s ease-in-out;
  // }
}

.relatedWrapper {
  .relatedTitle {
    font-size: 30px;
    font-weight: 700;
    padding-top: 50px;
  }
  .relatedArticles {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 48px;
    .individualArticle {
      width: 45%;
      div:nth-child(1) {
        // background-color: red;
        height: 20vh;
        // border: 1px solid var(--bordercolor-main);
        border-radius: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
      }
    }
  }
}

.mobileArticle {
  border-radius: 35px 35px 0px 0px;
  margin-top: -100px;
  padding: 40px 12px;
  background-color: var(--highlight-color-opposite);
  z-index: 1;
  padding-bottom: 5rem;
  .articleContent {
    img {
      width: 100% !important;
      max-width: 100% !important;
      // object-fit: cover;
      height: 100%;
    }

    ul {
      width: 100%;
      margin: auto 0;
      line-height: 1.6;
    }
    li {
      width: 100%;
      margin: auto 0;
      line-height: 1.6;
    }
    span {
      width: 100%;
      margin: auto 0;
      line-height: 1.6;
      white-space: pre-wrap;
      border: none !important;
    }
  }
}

.mobileArticle span {
  max-width: 100%;
}

.articleSaveMenu {
  // position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: white;
  z-index: 2;
  display: flex;
  border-top: 0.5px solid var(--bordercolor-main);
  padding: 5px 12px;
  // padding-bottom: 0;

  & > .primaryButton {
    background: var(--background-color);
    border-radius: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--font-color-opposite);
    padding: 0 1.75rem;
    width: max-content;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    white-space: nowrap;
    transition: all ease-in 0.4s;
    margin-right: 15px;
  }

  & > .socialMediaIcons {
    display: flex;
    // justify-content: space-between;
    gap: 1rem;
    width: -webkit-fill-available;
    color: #4b2a91;
    overflow-x: scroll;

    & > a {
      text-decoration: none;
      color: inherit;
      // padding: 1rem;
      width: 50px;
      height: 50px;
      background: var(--font-color-opposite);
      // border: 1px solid var(--bordercolor-main);
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in 0.4s;

      & > img {
        height: 25px;
        width: 25px;
      }

      &:hover {
        transform: translateY(-10px);
      }
    }

    & > div {
      // padding: 1rem;
      width: 50px;
      height: 50px;
      background: var(--font-color-opposite);
      border: 1px solid var(--bordercolor-main);
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in 0.4s;
      min-width: 50px;
      max-width: 50px;

      & > svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.articleMobileTitle {
  font-weight: 600;
  font-size: 25px;
  line-height: 35px;
}

.metadata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px;
  .author {
    display: flex;
    align-items: center;
    img {
      height: 28px;
      width: 28px;
      border-radius: 50%;
    }
    div {
      padding-left: 10px;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .timestamp {
    font-weight: 600;
    font-size: 14px;
  }
}

.popupDialogue {
  // position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--highlight-color-opposite);
  border-radius: 20px;
  padding: 30px 40px;
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .popupTitle {
    font-weight: 800;
    font-size: 25px;
    line-height: 35px;
  }
  .popupContent {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    padding: 20px 0px;
  }
}

.primaryButton {
  width: 100%;
  background: var(--background-color);
  color: var(--font-color-opposite);
  padding: 20px 0px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid #4b2a91;
  &:hover {
    background-color: var(--highlight-color-opposite);
    color: #4b2a91;
    border: 1px solid #4b2a91;
    transition: all 0.3s ease-in-out;
  }
}

.secondaryButton {
  margin-top: 20px;
  width: 100%;
  background-color: var(--highlight-color-opposite);
  color: #4b2a91;
  border: 1px solid #4b2a91;
  padding: 20px 0px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}

.floatingBar {
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: var(--font-color-opposite);
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  // position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
  }

  & > :nth-child(1) {
    cursor: pointer;
    border-right: 1px solid #ebebeb;
  }

  & > :nth-child(2) {
    width: 70%;
    & > div {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;
      font-size: 1rem;
      color: var(--font-color);
      height: 100%;
    }

    & > :nth-child(1) {
      border-right: 1px solid #ebebeb;
    }
  }

  & > :nth-child(3) {
    cursor: pointer;
    border-left: 1px solid #ebebeb;

    & > img {
      rotate: 180deg;
    }
  }
}
