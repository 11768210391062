.customInput {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .title {
    font-size: 1rem;
    font-weight: 510;
  }

  & > .inputContainer {
    position: relative;
    display: flex;
    border-radius: 6px;

    & > .inputText {
      height: 100%;
      padding: 1.35rem 1.5rem;
      // border: 1px solid #e5e5e5;
      width: 30%;
      font-weight: 510;
      border-radius: 6px;
      border-right: 0;
      font-size: 1.1rem;
      border-right: 1px solid #e5e5e5;
    }

    & > input {
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      outline: none;
      padding: 1.35rem 1.5rem;
      width: 100%;
      height: 100%;

      &:focus {
        border-color: var(--main-color);
      }
    }

    & > .availableContainer {
      padding: 0.95rem 1.5rem;
      background: var(--main-color);
      color: white;
      border-radius: 6px;
      position: absolute;
      top: 50%;
      right: 1rem;
      transform: translate(0%, -50%);
    }

    & > .addTag {
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translate(0%, -50%);
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  & > .allTags {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;

    & > div {
      padding: 1.2rem 2rem;
      border-radius: 6px;
      border: 1px solid #d9d9d9;
    }
  }
}
