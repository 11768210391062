// .multi-select {
//   margin-bottom: 20px;
// }

// .multi-select-label {
//   display: block;
//   margin-bottom: 8px;
//   font-size: 20px;
//   font-weight: 400;
//   width: 140px;
// }
// .multi-row-wrapper {
//   display: flex;
//   gap: 20px;
//   justify-content: start;
//   align-items: center;
// }

// .input-wrapper {
//   position: relative;
//   flex: 1;
// }

// .input-box {
//   width: 100%;
//   padding: 12px 16px;
//   border: 0.66px solid #8f8f8f;
//   border-radius: 8px;
//   outline: none;
//   transition: border-color 0.2s;
// }

// .input-box:focus {
//   border-color: #d6bbfb;
// }

// .dropdown {
//   position: absolute;
//   width: 100%;
//   max-height: 150px;
//   overflow-y: auto;
//   background-color: white;
//   border: 1px solid #d0d5dd;
//   border-radius: 8px;
//   z-index: 10;
//   margin-top: 2px;
// }

// .dropdown-item {
//   padding: 8px 12px;
//   cursor: pointer;
//   transition: background-color 0.2s;
// }

// .dropdown-item:hover {
//   background-color: #f0f0f0;
// }

// .selected-options {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 8px;
//   margin-top: 10px;
// }

// .chip {
//   background-color: #e0e7ff;
//   color: #3730a3;
//   padding: 5px 10px;
//   border-radius: 16px;
//   display: flex;
//   align-items: center;
// }

// .remove-chip {
//   margin-left: 8px;
//   cursor: pointer;
// }

.multi-select {
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: start;
  min-width: 60%;
}

.multi-select-label {
  display: block;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 400;
  width: 140px;
}

.input-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 8px;
  border: 0.66px solid #8f8f8f;
  border-radius: 8px;
  position: relative;
}

.input-box {
  flex: 1;
  min-width: 150px;
  border: none;
  outline: none;
  padding: 4px 8px;
  margin-left: 8px;
}

.input-box:focus {
  border-color: #d6bbfb;
}

.dropdown {
  position: absolute;
  width: 100%;
  max-width: 100%;

  max-height: 150px;
  max-width: 40%;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  z-index: 10;
  margin-top: 2px;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.chip {
  display: flex;
  align-items: center;
  background-color: white;
  color: #3d3d3d;
  padding: 5px 10px;
  border-radius: 16px;
  border: 0.66px solid #666666;
  margin-right: 8px;
}

.remove-chip {
  margin-left: 8px;
  cursor: pointer;
}
