.eigth-section {
    width: 100%;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eigth-section>div {
    padding: var(--common-padding);
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.eight-section-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 65%;
}

.eight-section-content>div {
    display: flex;
    color: #0A0B0B;
}



.eight-section-content>:first-child {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.5;
    text-align: center;
}

.eight-section-content>:first-child {
    display: block;
    text-align: left;
}

.eight-content>:last-child {
    font-size: 2rem;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eight-content>:first-child {
    width: 100px;
    height: 100px;
}

.eight-content>:first-child img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.eigth-section-image {
    width: 25%;
}

.eigth-section-image img {
    max-width: 100%;
}

@media screen and (max-width:700px) {
    .eigth-section-image {
        display: none;
    }

    .eight-section-content {
        width: 100%;
    }

    .eight-content>:first-child {
        width: 60px;
        height: 60px;
    }
}