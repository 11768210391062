.chatContainer {
  gap: 20px;

  .chatBox {
    width: 100%;
  }

  .inner-report {
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .addnewbutton {
    width: 92%;
    height: 39px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.07px;
    text-align: left;
    background: #58b135;
    position: absolute;
    bottom: 0.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 12px;
    z-index: index 99999;
    &:hover {
      transform: scale(1.03);
    }
  }

  .chat-sec {
    width: 60%;
    height: calc(100% - 1px);
  }

  .chatlabelemail {
    width: 169px;
    height: 45px;
    border-radius: 10px;

    font-family: Montserrat;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.29px;
    text-align: center;
    cursor: pointer;
    color: #000000;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;

    &:hover {
      transform: scale(1.03);
    }
  }

  .chatlabel-sec {
    display: flex;
    align-items: start;
    gap: 10px;
    margin-bottom: 1.5rem;
  }

  .chatlabel {
    width: 169px;
    height: 45px;
    border-radius: 10px;

    font-family: Montserrat;
    font-size: 15px;
    font-weight: 700;
    line-height: 18.29px;
    text-align: center;

    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #58b135;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
    }
  }

  .chat-main {
    width: 100%;
    height: calc(100% - 1px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .goals-reports {
      display: flex;
      gap: 10px;
      width: 100%;
      height: calc(100% - 269px);

      padding: 2px;

      &::-webkit-scrollbar {
        display: none;
      }

      .goals {
        width: 55%;
        box-shadow: 0px 1px 3px 0px #0000004d, 0px 1px 8px 2px #00000026;
        padding: 20px;
        margin-right: 10px;
        border-radius: 8px;
        height: 98%;
        position: relative;
        padding-bottom: 5rem;

        h4 {
          margin-bottom: 10px;
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 26.06px;
          text-align: left;
        }

        .goals-child {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #a3a3a3;
          padding: 15px 0;

          &:hover {
            transform: scale(1.03);
          }

          img {
            margin-right: 10px;
          }

          .goals-toy {
            .goals-title {
              font-family: "Hanken Grotesk", sans-serif;
              font-size: 14px;
              font-weight: 600;
              line-height: 18.24px;
              text-align: left;
              color: #000000;
            }

            .goals-priority {
              font-family: "Hanken Grotesk", sans-serif;
              font-size: 12px;
              font-weight: 400;
              line-height: 15.64px;
              text-align: left;
              color: #8f8f8f;
            }
          }
        }
      }

      .reportsBox {
        width: 45%;
        height: 100%;
        height: 98%;
        box-shadow: 0px 1px 3px 0px #0000004d, 0px 1px 8px 2px #00000026;
        position: relative;
      }
    }
  }

  .investment {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 250px;
    box-shadow: 0px 1px 3px 0px #0000004d, 0px 1px 8px 2px #00000026;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;

    .investment-title {
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 26.06px;
      text-align: left;
      color: #000000;
    }

    .investment-text {
      width: 50%;

      .investment-details {
        margin-top: 40px;

        .investment-total {
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 16px;
          font-weight: 600;
          line-height: 20.85px;
          text-align: left;
          color: #3d3d3d;
        }

        .investment-amount {
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.85px;
          text-align: left;
          color: #525252;
        }
      }
    }

    .investment-avg {
      display: flex;
      align-items: flex-end;
      gap: 10px;
      position: relative;
      width: 50%;

      .fund-box {
        //   width: 75px;
        //   height: 75px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;

        &.mutual-funds {
          width: 70%;
          height: 90%;
          background: #58b135;
          z-index: 1;
          left: 0;
          display: flex;
          justify-content: space-evenly;
          padding-left: 16px;
        }

        &.gold {
          width: 40%;
          height: 50%;
          background: #eff0f3;

          z-index: 2;
          right: 80px;
          display: flex;
          justify-content: space-evenly;
          padding-left: 16px;
        }

        &.others {
          width: 40%;
          height: 60%;
          background: #58b135;
          z-index: 3;
          right: 0;
          display: flex;
          justify-content: space-evenly;
          padding-left: 16px;
        }

        .fund-percentage {
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 26.06px;
          text-align: left;
          color: #ffffff;
          margin-right: auto;
        }

        .fund-type {
          font-family: "Hanken Grotesk", sans-serif;
          font-size: 18px;
          font-weight: 600;
          line-height: 23.45px;
          text-align: left;
          color: #ffffff;
          margin-right: auto;
        }

        &.gold .fund-percentage,
        &.others .fund-percentage {
          color: #3d3d3d;
        }

        &.gold .fund-type,
        &.others .fund-type {
          color: #3d3d3d;
        }
      }
    }
  }
}

.searchProfileContainer {
  padding: 0 20px;
  height: 65px;
  margin: 0;
  border-bottom: 1px solid #d9d9d9;

  .profileTextContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: none;
    padding: 5px 10px;
    border-radius: var(--p-border-radius-button);

    .profileName {
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 23.45px;
      text-align: left;
    }

    .topBar {
      display: flex;

      .profileIconsContainer {
        width: 60px;
        height: 60px;
        object-fit: cover;
        margin: 0 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .backButton {
        background-color: white;
        border: none;
      }
    }
  }
}
