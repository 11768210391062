
.advisor-edit-article-main {
    padding: 0 20px;

    .article-card {
      width: 80%;
      padding: 12px;
      border-radius: 10px;
      background: #FBFBFB;
      border: 1px solid #E0E0E0;
      position: relative;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;
      overflow: visible;
      margin-bottom: 25px;
  
      .card-content {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;
      }
  
      input, textarea {
        width: 100%;
        border: 1px solid #8F8F8F;
        border-radius: 10px;
        padding: 12px;
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 16px;
        line-height: 20.85px;
        color: #000000;
      }
      
      textarea{
        height: 100px;
      }

      .title-input {
        font-weight: 600;
      }
  
      .description-input {
        resize: none;
        width: 90%;
        overflow-y: auto;
        color: #3D3D3D;


        &::-webkit-scrollbar {
            display: none; 
          }
    
      }
      
      .tags-input{
        width: 90%;
        color: #3D3D3D;

      }

      .card-image {
        margin-top: 12px;
        width: 20%;
  
        img {
          border-radius: 12px;
          max-width: 100%;
          height: 101px;
          object-fit: cover;
        }
      }
  
      .delete-button {
        position: absolute;
        top: 12px;
        right: -40px;
        background: none;
        border: none;
        cursor: pointer;
        z-index: 10;
  
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  
    .add-button {
      display: flex;
      align-items: center;
      gap: 8px;
      border: 1px solid #1d5b05;
      padding: 8px 12px;
      border-radius: 8px;
      background: none;
      color: #666666;
      cursor: pointer;
      font-family: 'Hanken Grotesk', sans-serif;
      font-size: 16px;
      line-height: 20.85px;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        padding: 20px;
  
        .cancel-button, .save-button {
          font-family: 'Hanken Grotesk', sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 26.06px;
          text-align: center;
          padding: 10px 24px;
          border-radius: 16px;
          cursor: pointer;
        }
  
        .cancel-button {
          color: #1d5b05;
          background: none;
          border: 1px solid #1d5b05;
        }
  
        .save-button {
          color: #fff;
          background: #58B135;
          border: none;
        }
      }
  }