.rightsidedatashow {
  padding: 0 20px;
  overflow-y: auto;

  .topBar {
    display: flex;
    justify-content: flex-start;
    padding: 20px;

    .pageTitle {
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 26.06px;
      color: #000000;
      margin-right: 5px;
      padding-left: 15px;
    }
  }

  .flex_preview {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .share_button {
      background: #58b135;
      padding: 10px 24px;
      border-radius: 16px;
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 26.06px;
      text-align: center;
      color: #ffffff;
      border: none;
      margin-bottom: 20px;
    }
  }

  .reportPage {
    padding: 20px;
  }

  .reportContainer {
    box-shadow: 1px 1px 4px 0px #00000040;
    padding: 20px;
    border-radius: 8px;

    .reportTitle {
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 26.06px;
      text-align: center;
      color: #000000;
      margin-bottom: 20px;
    }

    .sectionTitle {
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 23.45px;
      text-align: left;
      color: #000000;
      margin-bottom: 10px;
    }

    .reportText {
      font-family: "Hanken Grotesk", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20.85px;
      text-align: left;
      color: #525252;
      margin-bottom: 20px;
    }

    .reportTable {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;

      th {
        border-width: 1px 0px 0px 1px;
        border-style: solid;
        border-color: #dddddd;
        background: #f5f5f5;
        padding: 16px 12px;
      }

      td {
        border: 1px solid #dddddd;
        padding: 16px 12px;
      }
    }
  }
}
