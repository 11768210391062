.upload-component {
    padding: 0 0 20px;
    background: #fff;
    border-radius: 10px;
    width: 90%;
  }
  
  .hashtags-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.hashtag-input {
      text-align: center;
    padding: 12px 16px;
    border: 1px solid #8F8F8F;
    border-radius: 10px;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.85px;
    color: #666666;
  }
  
  .hashtags-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .hashtag {
    padding: 12px 16px;
    border: 1px solid #8F8F8F;
    border-radius: 10px;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.85px;
    color: #666666;
    display: flex;
    align-items: center;
  }
  
  .remove-hashtag-button {
    background: none;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    color: red;
    font-size: 16px;
  }
