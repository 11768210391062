.plan-section {
    /* min-height: calc(98vh - 86px); */
    /* height: 160vh; */
    width: 100%;
    padding-top: 8rem;
    padding-bottom: 5rem;
}

.plan-section>div {
    max-width: 1250px;
    margin: auto;
}

.plan-section-heading {
    display: flex;
    align-items: center;
    width: 100%;
    height: 10%;
}

.plan-section-heading img {
    height: 5.4rem;
    margin-left: 2.5rem;
}

.plan-section-content {
    display: grid;
    /* height: 90%; */
    grid-template-columns: repeat(2, 1fr);
    margin-top: 8rem;
    gap: 4rem;
}

.plan-section-content>div {
    width: 100%;
    height: 100%;
    /* max-height: 400px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7%;
}

.plan-section-content>div>div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plan-section-t1 {
    font-size: 22px;
    font-weight: 700;
    color: #110F0D;
    white-space: nowrap;
    z-index: 1;
}

.plan-section-arrow {
    font-size: 3rem;
    color: white;
    background: #58B135;
    width: max-content;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
}

.plan-section-content>:nth-child(1),
.plan-section-content>:nth-child(2) {
    border-radius: 15px;
    border: 1px solid #EFEFEF;
    background: #F3F4F5;
}

.plan-section-content>:nth-child(2),
.plan-section-content>:nth-child(4) {
    /* padding: 10% 5%; */
    border-radius: 15px;
    border: 1px solid #EFEFEF;
    background: #F3F4F5;
}

.plan-section-content>:nth-child(1),
.plan-section-content>:nth-child(3) {
    /* padding-left: 0; */
    border-radius: 15px;
    border: 1px solid #EFEFEF;
    background: #F3F4F5;
}

@media screen and (min-width:2000px) {
    /* .plan-section-content>div {
        padding: 20%;
    } */
}

@media screen and (max-width:600px) {
    .plan-section-content {
        grid-template-columns: repeat(1, 1fr);
    }

    .hide-mobile-text {
        display: none;
    }

    .plan-section-content>:nth-child(1),
    .plan-section-content>:nth-child(2) {
        border-bottom: none;
    }

    .plan-section-content>:nth-child(2),
    .plan-section-content>:nth-child(4) {
        border-left: none;
        padding: 10% 0;
    }
}

@media screen and (max-width:720px) {

    .plan-section-heading {
        height: 10vh;
    }

    .plan-section-content>:nth-child(1),
    .plan-section-content>:nth-child(2) {
        height: calc(42vh - 28.5px);
    }

    .plan-section-content>:nth-child(3),
    .plan-section-content>:nth-child(4) {
        height: calc(49.5vh - 28.5px);
    }

    .plan-section-content>div>div {
        justify-content: space-evenly;
    }
}