.container-cust{
    &>.image-overlay {
        position: relative;
        width: 288px;
        height: 200px;
        overflow: hidden;
        border-radius: 8px;
        
        &>.background-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        }
    
        &>.overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: transparent;
        text-align: center;
        place-self: center;
        &>i {
            font-size: 24px;
            margin-bottom: 10px;
        }
    
        &>p {
            margin: 0;
            font-size: 14px;
            color: white;
        }
        }
    }
    &>.text-inputs {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        max-width: 600px;
    
        &>.heading-input {
        font-size: 18px;
        font-weight: bold;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        }
    
        &>.paragraph-input {
        font-size: 16px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        height: 170px;
        resize: vertical;
        font-family: "Hanken Grotesk", sans-serif;
        }
  }
  &>.action{
    padding-top: 10px;
    &>.btn {
        padding: 10px 24px;
        border: none;
        cursor: pointer;
        background: #58B135;
        color: white;
        border-radius: 16px;
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 26.06px;
        text-align: center;
    }
  }
  
}