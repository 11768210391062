.containers {
    display: flex;
    flex-direction: column;
    width: 100%;
    // padding: 20px;
    font-family: Arial, sans-serif;
    
    &>.title{
        display: flex;
        
        width: 100%;
        margin: auto;
        justify-content: space-between;
        margin-bottom: 15px;
        &>h3{
            font-size: 24px;
            font-weight: 700;
        }
        &>img{
            width: 22px;
            height: 22px;

            &:hover{
                cursor: pointer;
            }
        }
    }
    & > .share-icons{
        width: 48px;
        height: 48px;
        object-fit: cover;
        display: flex;
        justify-content: space-between;
    }
    & > .contents {
        padding-top: 20px;
        // width: 50%;
        
        &>img{
            width: 288px;
            height: 200px;
        }
        &>h2{
            font-size: 24px;
            padding: 20px 0 10px;
        }
        &>p{
            font-size: 18px;
            color:#666666;
            font-family: "Hanken Grotesk", sans-serif;
            width: 80%;
        }
        &>.contact-info {
            padding-top: 20px;
            width: 40%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &>.contact-details{
                padding-left: 14px;
                padding-top: 5px;
                &>h3{
                    font-size: 16px;
                }
                &>p{
                    font-size: 14px;
                }
            }
            &>img{
                border-radius: 50%;
                width: 66px;
                height: 66px;
                display: block;
            }
        }
    }
    &>.actions {
        display: flex;
        gap: 10px;
        width: 100%;
        
        justify-content: flex-end;
        &>.btn {
            padding: 10px 24px;
            border: none;
            cursor: pointer;
            background: #58B135;
            color: white;
            border-radius: 16px;
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 26.06px;
            text-align: center;
        }
        & > .share{
            color: #007bff;
            background-color: white;
            border:1px solid #666666;
        }
      }
}