.upload-component {
    padding: 20px 0;
    background: #fff;
    border-radius: 10px;
    width: 90%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .product-label {
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 23.45px;
            text-align: left;
            color: #525252;
        }

        .delete-icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
            object-fit: contain;
        }
    }

    .form-container {
        border: 1px solid #E0E0E0;
        border-radius: 10px;
        padding: 10px;

        .form-group {
            label {
                margin-bottom: 5px;
                font-size: 16px;
                font-family: Roboto;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: center;
                color: #666666;
            }

            input[type="text"] {
                width: 100%;
                padding: 10px;
                border: 1px solid #8F8F8F;
                border-radius: 10px;
            }

            input[type="file"] {
                width: 100%;
            }

            p {
                font-family: Roboto;
                font-size: 17px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.10000000149011612px;
                text-align: center;
                color: #3D3D3D;
                padding-top: 25px;

                .file-input-label {
                    cursor: pointer;
                    color: #000000;
                    text-decoration: underline;
                    position: relative;
                    font-weight: 500;
                    font-size: 18px;

                    input[type="file"] {
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        padding: 0;
                        margin: -1px;
                        overflow: hidden;
                        clip: rect(0, 0, 0, 0);
                        border: 0;
                    }
                }
            }
        }

        .title-input {
            text-align: center;
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 20.85px;
            border: 1px solid #8F8F8F;
            margin-bottom: 10px;
        }

        .form-desc-p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            .form-group {
                height: 280px;
                border: 1px solid #8F8F8F;
                border-radius: 10px;

                &:first-of-type {
                    width: 65%;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    justify-content: space-evenly;
                    align-items: center;
                }

                &:last-of-type {
                    width: 35%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .add-one-container {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;

        .add-one-button {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 8px 12px;
            border-radius: 8px;
            border: 1px solid #1d5b05;
            font-family: 'Hanken Grotesk', sans-serif;
            font-size: 16px;
            font-weight: 600;
            line-height: 20.85px;
            text-align: left;
            color: #666666;
            background: white;
            cursor: pointer;
        }
    }
}

.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 20px;

    .cancel-button,
    .save-button {
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 26.06px;
        text-align: center;
        padding: 10px 24px;
        border-radius: 16px;
        cursor: pointer;
    }

    .cancel-button {
        color: #1d5b05;
        background: none;
        border: 1px solid #1d5b05;
    }

    .save-button {
        color: #fff;
        background: #58B135;
        border: none;
    }
}

.add-existing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    padding-bottom: 15px;

    a {
        font-family: 'Hanken Grotesk', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 26.06px;
        text-align: left;
        color: #525252;
    }
}
