.eleventh-section {
    width: 100%;
    min-height: 70vh;
    padding: 5vh var(--common-padding);
    margin: 5vh 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.eleventh-section>div {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.eleventh-content {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    justify-content: center;
    align-items: center;
}

.eleventh-content>:first-child {
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.5;
    color: #0A0B0B;
    /* text-align: center; */
}

.eleventh-content>:last-child {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0A0B0B;
    /* text-align: center; */
}

.eleventh-section>div>div:last-child {
    width: 45%;
    height: 100%;
}

.eleventh-section>div>div:last-child img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

@media screen and (max-width:500px) {
    .eleventh-section>div {
        flex-direction: column;
    }

    .eleventh-section>div>div {
        width: 100%;
    }

    .eleventh-section>div>div:last-child {
        width: 80%;
    }
}